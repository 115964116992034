import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import { H4, P2 } from '../../Atoms/Typo';

import DounutChart from '../../Molecule/Chart/DonutChart';
import DetailButton from '../../Molecule/DetailButton';
import DefaultCardHeading from '../../Molecule/Heading/Card/DefaultCardHeading';
import DefaultModal from '../../Molecule/Modal/DefaultModal';
import DefaultPagenation from '../../Molecule/Pagenation';
import Spinner from '../../Molecule/Spinner';
import Table, { TableProps } from '../../Molecule/Table';

const Component = styled(CardLayout)`
  min-height: 476px;
`;

const Body = styled(CardBodyLayout)`
  margin-top: 24px;
`;

const PaginationWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
`;

export interface DounutChartCardProps {
  data: {
    [key: string]: string | number;
    cnt: number;
    rate: number;
  }[];
  title: string;
  isLoading: boolean;
}

const DounutChartCard = ({ data, title, isLoading }: DounutChartCardProps) => {
  const [isOpenTable, setIsOpenTable] = useState(false);
  const [tableData, setTableData] = useState<TableProps['data']>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [lastPageNumber, setLastPageNumber] = useState(1);

  const columns = [
    {
      title,
      id: 'type',
      isSorting: false,
    },
    {
      title: '방문 비율',
      id: 'rate',
      isSorting: false,
    },
  ];

  const onClickDetailButton = () => {
    setIsOpenTable(true);
  };

  const onClickModalClose = () => {
    setIsOpenTable(false);
  };

  const onChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };

  const genTableData = () => {
    const data_temp: TableProps['data'] = [];
    if (data.length > 0) {
      for (
        let index = (pageNumber - 1) * 9;
        index <= pageNumber * 9 - 1;
        index += 1
      ) {
        if (index > data.length - 1) {
          break;
        }
        const element = data[index];
        const keyArr = Object.keys(element);
        const key = keyArr[0];
        if (element.rate !== 0) {
          data_temp.push({
            id: `${index}`,
            type: <P2>{element[key]}</P2>,
            rate: <H4>{element.rate}%</H4>,
          });
        } else {
          data_temp.push({
            id: `${index}`,
            type: <P2>{element[key]}</P2>,
            rate: <H4>0.01% 미만</H4>,
          });
        }
      }
    }
    setTableData(data_temp);
  };

  useEffect(() => {
    genTableData();
  }, [pageNumber]);

  useEffect(() => {
    setLastPageNumber(Math.ceil(data.length / 9));
    genTableData();
  }, [data]);

  return (
    <Component>
      <DefaultModal
        isOpen={isOpenTable}
        title={title}
        onClickCloseButton={onClickModalClose}
      >
        <Table columns={columns} data={tableData} />
        <PaginationWrapper>
          <DefaultPagenation count={lastPageNumber} onChange={onChangePage} />
        </PaginationWrapper>
      </DefaultModal>
      <CardHeaderLayout>
        <DefaultCardHeading title={title} />
        <DetailButton onClick={onClickDetailButton} />
      </CardHeaderLayout>
      <Body>{isLoading ? <Spinner /> : <DounutChart data={data} />}</Body>
    </Component>
  );
};

export default DounutChartCard;
