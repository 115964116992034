import React from 'react';

import styled from 'styled-components';
import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import CardHeader from '../../../Molecule/Heading/Card/CardHeader';
import { P2, P1 } from '../../../Atoms/Typo';
import Spinner from '../../../Molecule/Spinner';

const Compoent = styled(CardLayout)`
  margin: 0px 0px 20px;
`;

const CardBody = styled(CardBodyLayout)`
  word-break: break-all;
  ${P1} {
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
  }
`;

const Wrap = styled.div`
  :first-child {
    margin-bottom: 20px;
  }
`;

interface BasicInformCardProps {
  isLoading?: boolean;
  id: string;
  user_key: string[];
}

const BasicInformCard = ({ isLoading, id, user_key }: BasicInformCardProps) => {
  return (
    <Compoent>
      <CardHeaderLayout>
        <CardHeader title="기본 정보" />
      </CardHeaderLayout>
      {isLoading ? (
        <Spinner />
      ) : (
        <CardBody>
          <Wrap>
            <P1>ID</P1>
            <P2>{id}</P2>
          </Wrap>
          <Wrap>
            <P1>User Key</P1>
            {user_key.map((element) => {
              return <P2 key={element}>- {element}</P2>;
            })}
          </Wrap>
        </CardBody>
      )}
    </Compoent>
  );
};

export default BasicInformCard;
