import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import loading from './loading';

import authentication, { authenticationSaga } from './authentication';
import serviceDashboard, {
  serviceDashboardSaga,
} from './serviceAnalytics/dashboard/index';
import serviceUserAnalysis, {
  serviceUserAnalysisSaga,
} from './serviceAnalytics/userAnalysis';
import servicePageViewAnalysis, {
  servicePageViewAnalysisSaga,
} from './serviceAnalytics/pageViewAnalysis';
import serviceSessionAnalysis, {
  serviceSessionAnalysisSaga,
} from './serviceAnalytics/sessionAnalysis';
import environmentAnalysis, {
  environmentAnalysisSaga,
} from './serviceAnalytics/environmentAnalysis';

import pageList, { pageListSaga } from './PageAnalytics/PageList';

import pageUXAnalytics, {
  pageUXAnalyticsSaga,
} from './PageAnalytics/UXAnalytics';
import userFlowAnalysis, {
  userFlowAnalysisSaga,
} from './PageAnalytics/UserFlowAnalysis';
import mouseFlow, {
  mouseFlowSaga,
} from './PageAnalytics/UXAnalytics/MouseFlow';
import scroll, { scrollSaga } from './PageAnalytics/UXAnalytics/Scroll';

import aivoryDashboard, {
  aivoryDashboardSaga,
} from './aivoryUsageAnalytics/dashboard';
import aivorySearchUsageAnalysis, {
  aivorySearchUsageAnalysisSaga,
} from './aivoryUsageAnalytics/SearchUsageAnalysis';
import aivoryPopularSearchAnalysis, {
  aivoryPopularSearchAnalysisSaga,
} from './aivoryUsageAnalytics/popularSearchAnalysis';
import aivoryContentKeywordAnalysis, {
  aivoryContentKeywordAnalysisSaga,
} from './aivoryUsageAnalytics/contentKeywordAnalysis';

import report, { reportSaga } from './report';

import sidebarNav from './sidebarNav';
import datePicker, { datePickerSaga } from './datePicker';
import myPage, { myPageSaga } from './myPage';
import userData, { userDataSaga } from './userData';
import foldNav from './foldNav';

const rootReducer = combineReducers({
  loading,
  authentication,
  serviceDashboard,
  serviceUserAnalysis,
  servicePageViewAnalysis,
  serviceSessionAnalysis,
  environmentAnalysis,
  pageUXAnalytics,
  userFlowAnalysis,
  pageList,
  sidebarNav,
  datePicker,
  aivoryDashboard,
  aivorySearchUsageAnalysis,
  aivoryPopularSearchAnalysis,
  aivoryContentKeywordAnalysis,
  myPage,
  mouseFlow,
  scroll,
  report,
  userData,
  foldNav,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
export function* rootSaga() {
  yield all([
    authenticationSaga(),
    serviceDashboardSaga(),
    serviceUserAnalysisSaga(),
    servicePageViewAnalysisSaga(),
    serviceSessionAnalysisSaga(),
    environmentAnalysisSaga(),
    pageUXAnalyticsSaga(),
    pageListSaga(),
    userFlowAnalysisSaga(),
    aivoryDashboardSaga(),
    aivorySearchUsageAnalysisSaga(),
    aivoryPopularSearchAnalysisSaga(),
    aivoryContentKeywordAnalysisSaga(),
    myPageSaga(),
    mouseFlowSaga(),
    scrollSaga(),
    datePickerSaga(),
    reportSaga(),
    userDataSaga(),
  ]);
}
