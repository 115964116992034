import React, { useEffect, useState, useCallback } from 'react';
import { AxiosError } from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../modules';
import DefaultModal from '../../components/UIComponents/Modal/DefaultModal';
import { AxiosErrorType } from '../../modules/createRequestSaga';
import { setLogoutAsync } from '../../modules/authentication';
import { setErrorNullMypage } from '../../modules/myPage';
import { setErrorNullAivoryContentKeyword } from '../../modules/aivoryUsageAnalytics/contentKeywordAnalysis';
import { setErrorNullUxAnalytics } from '../../modules/PageAnalytics/UXAnalytics';
import { setErrorNullAivoryDashboard } from '../../modules/aivoryUsageAnalytics/dashboard';
import { setErrorNullAivoryPopularSearch } from '../../modules/aivoryUsageAnalytics/popularSearchAnalysis';
import { setErrorNullPageList } from '../../modules/PageAnalytics/PageList';
import { setErrorNullServiceDashboard } from '../../modules/serviceAnalytics/dashboard';
import { setErrorNullServiceEnv } from '../../modules/serviceAnalytics/environmentAnalysis';
import { setErrorNullServicePV } from '../../modules/serviceAnalytics/pageViewAnalysis';
import { setErrorNullServiceSession } from '../../modules/serviceAnalytics/sessionAnalysis';
import { setErrorNullServiceUV } from '../../modules/serviceAnalytics/userAnalysis';
import { setErrorNullUserFlow } from '../../modules/PageAnalytics/UserFlowAnalysis';
import { setErrorNullAivorySearchUsage } from '../../modules/aivoryUsageAnalytics/SearchUsageAnalysis';
import { setErrorNull as setMouseFlowErrorNull } from '../../modules/PageAnalytics/UXAnalytics/MouseFlow';
import { setErrorNull as setMouseScrollErrorNull } from '../../modules/PageAnalytics/UXAnalytics/Scroll';
import { setErrorNull as setReportErrorNull } from '../../modules/report';

const ErrorComponent = () => {
  const ErrorMessage = {
    '4010000': '로그인 시간이 만료되었습니다.\n다시 로그인 해주세요.',
    '4010001': '다른기기에서 로그인 하였습니다.\n다시 로그인 해주세요.',
    network: '네트워크 연결 오류로 로그아웃 되었습니다.',
    default: '긴급 서비스 점검중입니다.\n이용에 불편을 드리게 되어 죄송합니다.',
    // default:
    //   '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
  };

  const { dashboardError } = useSelector(
    (state: RootState) => state.serviceDashboard
  );
  const { userAnalysisError } = useSelector(
    (state: RootState) => state.serviceUserAnalysis
  );
  const { pageViewAnalysisError } = useSelector(
    (state: RootState) => state.servicePageViewAnalysis
  );
  const { sessionAnalysisError } = useSelector(
    (state: RootState) => state.serviceSessionAnalysis
  );
  const { environmentAnalysisError } = useSelector(
    (state: RootState) => state.environmentAnalysis
  );
  const { pageListError } = useSelector((state: RootState) => state.pageList);
  const { uxAnalyticsError } = useSelector(
    (state: RootState) => state.pageUXAnalytics
  );
  const { mouseflowError } = useSelector((state: RootState) => state.mouseFlow);
  const { uxAnalyticsScrollError } = useSelector(
    (state: RootState) => state.scroll
  );
  const { userFlowAnalysisError } = useSelector(
    (state: RootState) => state.userFlowAnalysis
  );
  const { aivoryDashboardError } = useSelector(
    (state: RootState) => state.aivoryDashboard
  );
  const { aivoryPopularSearchAnalysisError } = useSelector(
    (state: RootState) => state.aivoryPopularSearchAnalysis
  );
  const { aivorySearchUsageAnalysisError } = useSelector(
    (state: RootState) => state.aivorySearchUsageAnalysis
  );
  const { contentKeywordAnalysisError } = useSelector(
    (state: RootState) => state.aivoryContentKeywordAnalysis
  );
  const { reportError } = useSelector((state: RootState) => state.report);
  const {
    getConfirmPasswordError,
    getConfirmPasswordErrorMessage,
    setUserInfoError,
    setUserInfoErrorMessage,
    setUserPasswordError,
    setUserPasswordErrorMessage,
  } = useSelector((state: RootState) => state.myPage);

  const dispatch = useDispatch();

  const onSetLogout = () => {
    dispatch(setLogoutAsync.request());
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isNetworkError, setIsNetworkError] = useState(false);
  // let isNetworkError = false;
  const onClickModalConfirmButton = useCallback(() => {
    setIsModalOpen(false);
    if (isNetworkError) {
      onSetLogout();
    }
  }, [isNetworkError]);

  function isResponse(args: AxiosErrorType): args is AxiosError['response'] {
    return args.data !== undefined;
  }
  function isRequest(args: AxiosErrorType): args is AxiosError['request'] {
    return args.responseXML !== undefined;
  }

  const ErrorCodeSetMessage = (
    error: any,
    errorCodeMessage: {
      [key: string]: string;
    }
  ) => {
    try {
      let message = '';
      if (error) {
        if (isResponse(error) && error) {
          const key = error.data.code as string;
          if (Object.prototype.hasOwnProperty.call(errorCodeMessage, key)) {
            // isNetworkError = false;
            setIsNetworkError(false);
            message = errorCodeMessage[key];
          } else {
            setIsNetworkError(false);
            message = errorCodeMessage.default;
          }
        } else if (isRequest(error)) {
          setIsNetworkError(true);
          message = errorCodeMessage.network;
        } else {
          setIsNetworkError(false);
          message = errorCodeMessage.default;
        }

        setErrorMessage(message);
        setIsModalOpen(true);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const SetMessage = (error: any) => {
    try {
      let message = '';
      if (error) {
        if (isResponse(error) && error) {
          const key = error.data.code as string;
          if (key === '4010001') {
            setIsNetworkError(true);
            message = ErrorMessage['4010001'];
          } else if (key === '4010000') {
            setIsNetworkError(true);
            message = ErrorMessage['4010000'];
          } else {
            setIsNetworkError(false);
            message = ErrorMessage.default;
          }
        } else if (isRequest(error)) {
          setIsNetworkError(true);
          message = ErrorMessage.network;
        } else {
          setIsNetworkError(false);
          message = ErrorMessage.default;
        }
        setErrorMessage(message);
        setIsModalOpen(true);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    ErrorCodeSetMessage(setUserPasswordError, setUserPasswordErrorMessage);
  }, [setUserPasswordError]);

  useEffect(() => {
    ErrorCodeSetMessage(setUserInfoError, setUserInfoErrorMessage);
  }, [setUserInfoError]);

  useEffect(() => {
    ErrorCodeSetMessage(
      getConfirmPasswordError,
      getConfirmPasswordErrorMessage
    );
  }, [getConfirmPasswordError]);

  useEffect(() => {
    SetMessage(contentKeywordAnalysisError);
  }, [contentKeywordAnalysisError]);

  useEffect(() => {
    SetMessage(aivorySearchUsageAnalysisError);
  }, [aivorySearchUsageAnalysisError]);

  useEffect(() => {
    SetMessage(aivoryPopularSearchAnalysisError);
  }, [aivoryPopularSearchAnalysisError]);

  useEffect(() => {
    SetMessage(aivoryDashboardError);
  }, [aivoryDashboardError]);

  useEffect(() => {
    SetMessage(userFlowAnalysisError);
  }, [userFlowAnalysisError]);

  useEffect(() => {
    SetMessage(uxAnalyticsError);
  }, [uxAnalyticsError]);

  useEffect(() => {
    SetMessage(uxAnalyticsScrollError);
  }, [uxAnalyticsScrollError]);

  useEffect(() => {
    SetMessage(mouseflowError);
  }, [mouseflowError]);

  useEffect(() => {
    SetMessage(pageListError);
  }, [pageListError]);

  useEffect(() => {
    SetMessage(environmentAnalysisError);
  }, [environmentAnalysisError]);

  useEffect(() => {
    SetMessage(sessionAnalysisError);
  }, [sessionAnalysisError]);

  useEffect(() => {
    SetMessage(pageViewAnalysisError);
  }, [pageViewAnalysisError]);

  useEffect(() => {
    SetMessage(userAnalysisError);
  }, [userAnalysisError]);

  useEffect(() => {
    SetMessage(dashboardError);
  }, [dashboardError]);

  useEffect(() => {
    SetMessage(reportError);
  }, [reportError]);

  useEffect(() => {
    return () => {
      dispatch(setErrorNullMypage());

      dispatch(setErrorNullAivoryContentKeyword());
      dispatch(setErrorNullAivoryDashboard());
      dispatch(setErrorNullAivoryPopularSearch());
      dispatch(setErrorNullAivorySearchUsage());

      dispatch(setErrorNullUserFlow());
      dispatch(setErrorNullPageList());
      dispatch(setErrorNullUxAnalytics());
      dispatch(setMouseFlowErrorNull());
      dispatch(setMouseScrollErrorNull());

      dispatch(setErrorNullServiceDashboard());
      dispatch(setErrorNullServiceEnv());
      dispatch(setErrorNullServicePV());
      dispatch(setErrorNullServiceSession());
      dispatch(setErrorNullServiceUV());
      dispatch(setReportErrorNull());
    };
  }, []);

  return (
    <DefaultModal
      isPublic={false}
      isOpen={isModalOpen}
      message={errorMessage}
      handleClose={onClickModalConfirmButton}
    />
  );
};

export default ErrorComponent;
