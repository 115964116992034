import React from 'react';
import styled from 'styled-components';

import FutureDemandCard from '../../Organisms/Card/UserData/FutureDemandCard';
import PurchaseActivityCard from '../../Organisms/Card/UserData/PurchaseActivityCard';

import {
  GetFutureDemandResponseType,
  GetPageActivityResponseType,
  GetPurchaseActivityResponseType,
  GetSessionInfoResponseType,
  GetUserPreferenceResponseType,
} from '../../../api/userData/type';
import PageActivityCard from '../../Organisms/Card/UserData/PageActivityCard';
import SessionInfoCard from '../../Organisms/Card/UserData/SessionInfoCard';
import UserPreferenceCard from '../../Organisms/Card/UserData/UserPreferenceCard';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface UserDataRightGridProps {
  purchaseLoading: boolean;
  pageActLoading?: boolean;
  sessionLoading?: boolean;
  futureLoading?: boolean;
  preferenceLoading?: boolean;
  purchaseActData: GetPurchaseActivityResponseType['payload'] | null;
  pageActData: GetPageActivityResponseType['payload'] | null;
  sessionData: GetSessionInfoResponseType['payload'];
  futureData: GetFutureDemandResponseType['payload'] | null;
  preferenceData: GetUserPreferenceResponseType['payload'];
}

const UserDataRightGrid = ({
  purchaseLoading,
  pageActLoading,
  sessionLoading,
  futureLoading,
  preferenceLoading,
  purchaseActData,
  pageActData,
  sessionData,
  futureData,
  preferenceData,
}: UserDataRightGridProps) => {
  return (
    <Component>
      <FutureDemandCard loading={futureLoading} data={futureData} />
      <UserPreferenceCard loading={preferenceLoading} data={preferenceData} />
      <PurchaseActivityCard
        isLoading={purchaseLoading}
        purchaseActData={purchaseActData}
      />
      <PageActivityCard isLoading={pageActLoading} data={pageActData} />
      <SessionInfoCard
        isLoading={sessionLoading}
        sessionInfoData={sessionData}
      />
    </Component>
  );
};

export default UserDataRightGrid;
