import styled, { css } from 'styled-components';

import { ReactComponent as IconAimSVG } from '../../../assets/icons2/icon-aim.svg';
import { ReactComponent as IconAIvoryLogoSVG } from '../../../assets/icons2/icon-aivory-logo.svg';
import { ReactComponent as IconAnalytics1SVG } from '../../../assets/icons2/icon-analytics-1.svg';
import { ReactComponent as IconAnalytics2SVG } from '../../../assets/icons2/icon-analytics-2.svg';
import { ReactComponent as IconAnalyticsLineSVG } from '../../../assets/icons2/icon-anayltics-line.svg';
import { ReactComponent as IconArrowDownSVG } from '../../../assets/icons2/icon-arrow-down.svg';
import { ReactComponent as IconArrowLeftSVG } from '../../../assets/icons2/icon-arrow-left.svg';
import { ReactComponent as IconArrowRightSVG } from '../../../assets/icons2/icon-arrow-right.svg';
import { ReactComponent as IconArrowUpSVG } from '../../../assets/icons2/icon-arrow-up.svg';
import { ReactComponent as IconCalendarSVG } from '../../../assets/icons2/icon-calendar.svg';
import { ReactComponent as IconCheckCircleSVG } from '../../../assets/icons2/icon-check-circle.svg';
import { ReactComponent as IconCheckSVG } from '../../../assets/icons2/icon-check.svg';
import { ReactComponent as IconCheckboxOffSVG } from '../../../assets/icons2/icon-checkbox-off.svg';
import { ReactComponent as IconCheckboxOnSVG } from '../../../assets/icons2/icon-checkbox-on.svg';
import { ReactComponent as IconCloseSVG } from '../../../assets/icons2/icon-close.svg';
import { ReactComponent as IconContentsAnalySVG } from '../../../assets/icons2/icon-contents-analy.svg';
import { ReactComponent as IconDeleteSVG } from '../../../assets/icons2/icon-delete.svg';
import { ReactComponent as IconDesktopSVG } from '../../../assets/icons2/icon-desktop.svg';
import { ReactComponent as IconDevicesSVG } from '../../../assets/icons2/icon-devices.svg';
import { ReactComponent as IconDownloadSVG } from '../../../assets/icons2/icon-download.svg';
import { ReactComponent as IconErrorSVG } from '../../../assets/icons2/icon-error.svg';
import { ReactComponent as IconEyeOffSVG } from '../../../assets/icons2/icon-eye-off.svg';
import { ReactComponent as IconEyeOnSVG } from '../../../assets/icons2/icon-eye-on.svg';
import { ReactComponent as IconFillArrowDownSVG } from '../../../assets/icons2/icon-fill-arrow-down.svg';
import { ReactComponent as IconFillArrowUpSVG } from '../../../assets/icons2/icon-fill-arrow-up.svg';
import { ReactComponent as IconFilterSVG } from '../../../assets/icons2/icon-filter.svg';
import { ReactComponent as IconFlagSVG } from '../../../assets/icons2/icon-flag.svg';
import { ReactComponent as IconGraph1SVG } from '../../../assets/icons2/icon-graph-1.svg';
import { ReactComponent as IconHeatmapSVG } from '../../../assets/icons2/icon-heatmap.svg';
import { ReactComponent as IconHelpSVG } from '../../../assets/icons2/icon-help.svg';
import { ReactComponent as IconImgSVG } from '../../../assets/icons2/icon-img.svg';
import { ReactComponent as IconLaunchSVG } from '../../../assets/icons2/icon-launch.svg';
import { ReactComponent as IconLocationSVG } from '../../../assets/icons2/icon-location.svg';
import { ReactComponent as IconLocktimeSVG } from '../../../assets/icons2/icon-locktime.svg';
import { ReactComponent as IconMenuSVG } from '../../../assets/icons2/icon-menu.svg';
import { ReactComponent as IconMoreSVG } from '../../../assets/icons2/icon-more.svg';
import { ReactComponent as IconMultiChartSVG } from '../../../assets/icons2/icon-multi-chart.svg';
import { ReactComponent as IconNonimgSVG } from '../../../assets/icons2/icon-nonimg.svg';
import { ReactComponent as IconPageAnalySVG } from '../../../assets/icons2/icon-page-analy.svg';
import { ReactComponent as IconPageviewSVG } from '../../../assets/icons2/icon-pageview.svg';
import { ReactComponent as IconPaperSVG } from '../../../assets/icons2/icon-paper.svg';
import { ReactComponent as IconPhoneSVG } from '../../../assets/icons2/icon-phone.svg';
import { ReactComponent as IconPlusSVG } from '../../../assets/icons2/icon-plus.svg';
import { ReactComponent as IconSearchSVG } from '../../../assets/icons2/icon-search.svg';
import { ReactComponent as IconServiceAnalySVG } from '../../../assets/icons2/icon-service-analy.svg';
import { ReactComponent as IconSetting1SVG } from '../../../assets/icons2/icon-setting-1.svg';
import { ReactComponent as IconSetting2SVG } from '../../../assets/icons2/icon-setting-2.svg';
import { ReactComponent as IconSMArrowDownSVG } from '../../../assets/icons2/icon-sm-arrow-down.svg';
import { ReactComponent as IconSMArrowLeftSVG } from '../../../assets/icons2/icon-sm-arrow-left.svg';
import { ReactComponent as IconSMArrowRightSVG } from '../../../assets/icons2/icon-sm-arrow-right.svg';
import { ReactComponent as IconSMArrowUpSVG } from '../../../assets/icons2/icon-sm-arrow-up.svg';
import { ReactComponent as IconTabletSVG } from '../../../assets/icons2/icon-tablet.svg';
import { ReactComponent as IconTextSearchSVG } from '../../../assets/icons2/icon-text-search.svg';
import { ReactComponent as IconTimeSVG } from '../../../assets/icons2/icon-time.svg';
import { ReactComponent as IconUserAnalySVG } from '../../../assets/icons2/icon-user-analy.svg';
import { ReactComponent as IconUserflowSVG } from '../../../assets/icons2/icon-userflow.svg';
import { ReactComponent as IconWarningSVG } from '../../../assets/icons2/icon-warning.svg';
import { ReactComponent as IconWorldSVG } from '../../../assets/icons2/icon-world.svg';
import { ReactComponent as IconWysiwygSVG } from '../../../assets/icons2/icon-wysiwyg.svg';
import { ReactComponent as IconUPSVG } from '../../../assets/icons2/icon-up.svg';
import { ReactComponent as IconDownSVG } from '../../../assets/icons2/icon-down.svg';
import { ReactComponent as IconRadioSVG } from '../../../assets/icons2/btn-radio-default.svg';
import { ReactComponent as IconBotSVG } from '../../../assets/icons2/icon-bot.svg';
import { ReactComponent as IconBackSVG } from '../../../assets/icons2/icon-back.svg';
import { ReactComponent as IconProfileImgSVG } from '../../../assets/icons2/icon-profile.svg';
import { ReactComponent as IconPageImgSVG } from '../../../assets/icons2/ic-page.svg';
import { ReactComponent as IconClockImgSVG } from '../../../assets/icons2/ic-clock.svg';
import { ReactComponent as IconShopImgSVG } from '../../../assets/icons2/ic-shop.svg';
import { ReactComponent as IconBeautySVG } from '../../../assets/icons3/icon-beauty.svg';
import { ReactComponent as IconDigitalSVG } from '../../../assets/icons3/icon-digital.svg';
import { ReactComponent as IconFashionSVG } from '../../../assets/icons3/icon-fashion.svg';
import { ReactComponent as IconFoodSVG } from '../../../assets/icons3/icon-food.svg';
import { ReactComponent as IconInteriorSVG } from '../../../assets/icons3/icon-interior.svg';
import { ReactComponent as IconLivingSVG } from '../../../assets/icons3/icon-living.svg';
import { ReactComponent as IconSportsSVG } from '../../../assets/icons3/icon-sports.svg';
import { ReactComponent as IconToysSVG } from '../../../assets/icons3/icon-toys.svg';

const focus_fill = css`
  fill: white;
`;

const disabled_fill = css`
  fill: ${(props) => props.theme.colors.grey500};
`;

const default_fill = css`
  fill: ${(props) => props.theme.colors.purple3};
`;

export const IconAim = styled(IconAimSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconAIvoryLogo = styled(IconAIvoryLogoSVG)`
  g {
    path {
      ${default_fill};
    }
  }
  &.focus {
    g {
      path {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    g {
      path {
        ${disabled_fill};
      }
    }
  }
  &.default {
    g {
      path {
        ${default_fill};
      }
    }
  }
`;

export const IconAnalytics1 = styled(IconAnalytics1SVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconAnalytics2 = styled(IconAnalytics2SVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconAnalyticsLine = styled(IconAnalyticsLineSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconArrowDown = styled(IconArrowDownSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconArrowLeft = styled(IconArrowLeftSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconArrowRight = styled(IconArrowRightSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconArrowUp = styled(IconArrowUpSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconCalendar = styled(IconCalendarSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconCheckCircle = styled(IconCheckCircleSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconCheck = styled(IconCheckSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconCheckboxOff = styled(IconCheckboxOffSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconCheckboxOn = styled(IconCheckboxOnSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconClose = styled(IconCloseSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconContentsAnaly = styled(IconContentsAnalySVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconDelete = styled(IconDeleteSVG)`
  path {
    &:last-child {
      ${default_fill};
    }
  }
  &.focus {
    path {
      &:last-child {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    path {
      &:last-child {
        ${disabled_fill};
      }
    }
  }
  &.default {
    path {
      &:last-child {
        ${default_fill};
      }
    }
  }
`;

export const IconDesktop = styled(IconDesktopSVG)`
  path {
    &:last-child {
      ${default_fill};
    }
  }
  &.focus {
    path {
      &:last-child {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    path {
      &:last-child {
        ${disabled_fill};
      }
    }
  }
  &.default {
    path {
      &:last-child {
        ${default_fill};
      }
    }
  }
`;

export const IconDevices = styled(IconDevicesSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconDownload = styled(IconDownloadSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconError = styled(IconErrorSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconEyeOff = styled(IconEyeOffSVG)`
  g {
    path {
      ${default_fill};
    }
  }
  &.focus {
    g {
      path {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    g {
      path {
        ${disabled_fill};
      }
    }
  }
  &.default {
    g {
      path {
        ${default_fill};
      }
    }
  }
`;

export const IconEyeOn = styled(IconEyeOnSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconFillArrowDown = styled(IconFillArrowDownSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconFillArrowUp = styled(IconFillArrowUpSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconFilter = styled(IconFilterSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconFlag = styled(IconFlagSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconGraph1 = styled(IconGraph1SVG)`
  path {
    &:last-child {
      ${default_fill};
    }
  }
  &.focus {
    path {
      &:last-child {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    path {
      &:last-child {
        ${disabled_fill};
      }
    }
  }
  &.default {
    path {
      &:last-child {
        ${default_fill};
      }
    }
  }
`;

export const IconHeatmap = styled(IconHeatmapSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconHelp = styled(IconHelpSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconImg = styled(IconImgSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconLaunch = styled(IconLaunchSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconLocation = styled(IconLocationSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path,
    ellipse {
      ${focus_fill};
    }
  }
  &.disabled {
    path,
    ellipse {
      ${disabled_fill};
    }
  }
  &.default {
    path,
    ellipse {
      ${default_fill};
    }
  }
`;

export const IconLocktime = styled(IconLocktimeSVG)`
  g {
    path {
      ${default_fill};
    }
  }
  &.focus {
    g {
      path {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    g {
      path {
        ${disabled_fill};
      }
    }
  }
  &.default {
    g {
      path {
        ${default_fill};
      }
    }
  }
`;

export const IconMenu = styled(IconMenuSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconMore = styled(IconMoreSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconMultiChart = styled(IconMultiChartSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconNonimg = styled(IconNonimgSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconPageAnaly = styled(IconPageAnalySVG)`
  g {
    path {
      ${default_fill};
    }
  }
  &.focus {
    g {
      path {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    g {
      path {
        ${disabled_fill};
      }
    }
  }
  &.default {
    g {
      path {
        ${default_fill};
      }
    }
  }
`;

export const IconPageview = styled(IconPageviewSVG)`
  path {
    &:last-child {
      ${default_fill};
    }
  }
  &.focus {
    path {
      &:last-child {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    path {
      &:last-child {
        ${disabled_fill};
      }
    }
  }
  &.default {
    path {
      &:last-child {
        ${default_fill};
      }
    }
  }
`;

export const IconPaper = styled(IconPaperSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconPhone = styled(IconPhoneSVG)`
  path {
    &:last-child {
      ${default_fill};
    }
  }
  &.focus {
    path {
      &:last-child {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    path {
      &:last-child {
        ${disabled_fill};
      }
    }
  }
  &.default {
    path {
      &:last-child {
        ${default_fill};
      }
    }
  }
`;

export const IconPlus = styled(IconPlusSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconSearch = styled(IconSearchSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconServiceAnaly = styled(IconServiceAnalySVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconSetting1 = styled(IconSetting1SVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconSetting2 = styled(IconSetting2SVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconSMArrowDown = styled(IconSMArrowDownSVG)`
  g {
    path {
      ${default_fill};
    }
  }
  &.focus {
    g {
      path {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    g {
      path {
        ${disabled_fill};
      }
    }
  }
  &.default {
    g {
      path {
        ${default_fill};
      }
    }
  }
`;

export const IconSMArrowLeft = styled(IconSMArrowLeftSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconSMArrowRight = styled(IconSMArrowRightSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconSMArrowUp = styled(IconSMArrowUpSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconTablet = styled(IconTabletSVG)`
  path {
    &:last-child {
      ${default_fill};
    }
  }
  &.focus {
    path {
      &:last-child {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    path {
      &:last-child {
        ${disabled_fill};
      }
    }
  }
  &.default {
    path {
      &:last-child {
        ${default_fill};
      }
    }
  }
`;

export const IconTextSearch = styled(IconTextSearchSVG)`
  g {
    path {
      ${default_fill};
    }
  }
  &.focus {
    g {
      path {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    g {
      path {
        ${disabled_fill};
      }
    }
  }
  &.default {
    g {
      path {
        ${default_fill};
      }
    }
  }
`;

export const IconTime = styled(IconTimeSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconUserAnaly = styled(IconUserAnalySVG)`
  g {
    path {
      ${default_fill};
    }
  }
  &.focus {
    g {
      path {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    g {
      path {
        ${disabled_fill};
      }
    }
  }
  &.default {
    g {
      path {
        ${default_fill};
      }
    }
  }
`;

export const IconUserflow = styled(IconUserflowSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconWarning = styled(IconWarningSVG)`
  path {
    &:last-child {
      ${default_fill};
    }
  }
  &.focus {
    path {
      &:last-child {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    path {
      &:last-child {
        ${disabled_fill};
      }
    }
  }
  &.default {
    path {
      &:last-child {
        ${default_fill};
      }
    }
  }
`;

export const IconWorld = styled(IconWorldSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconWysiwyg = styled(IconWysiwygSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconUp = styled(IconUPSVG)``;

export const IconDown = styled(IconDownSVG)``;

export const IconRadio = styled(IconRadioSVG)`
  circle {
    &:last-child {
      stroke: ${(props) => props.theme.colors.grey400};
      r: 8;
      stroke-width: 2;
    }
  }

  &.checked {
    circle {
      &:last-child {
        stroke: ${(props) => props.theme.colors.primary};
        r: 7;
        stroke-width: 4;
      }
    }
  }
`;

export const IconBot = styled(IconBotSVG)`
  path {
    fill: black;
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconBack = styled(IconBackSVG)`
  margin: 30px 0 0 10px;
`;

export const IconProfile = styled(IconProfileImgSVG)`
  margin-right: 24px;
`;

export const IconPage = styled(IconPageImgSVG)``;
export const IconClock = styled(IconClockImgSVG)``;
export const IconShop = styled(IconShopImgSVG)``;

export const IconBeauty = styled(IconBeautySVG)``;
export const IconDigital = styled(IconDigitalSVG)``;
export const IconFashion = styled(IconFashionSVG)``;
export const IconFood = styled(IconFoodSVG)``;
export const IconInterior = styled(IconInteriorSVG)``;
export const IconLiving = styled(IconLivingSVG)``;
export const IconSports = styled(IconSportsSVG)``;
export const IconToys = styled(IconToysSVG)``;
