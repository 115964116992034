import React from 'react';
import styled from 'styled-components';
import { P2 } from '../../Atoms/Typo';

const Component = styled.div`
  margin-bottom: 24px;
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  ${P2} {
    color: ${(props) => props.theme.colors.black};
    font-weight: bold;
    :last-child {
      font-weight: normal;
    }
  }
`;

const BarWrap = styled.div`
  height: 12px;
  width: 100%;
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.grey300};
`;

const ProgressData = styled.div<{ $rate: number }>`
  height: 12px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.orange6};
  transition: 1s ease;
  transition-delay: 0.5s;
  width: ${(props) => `${props.$rate}%`};
`;

interface ProgressBarProps {
  data: {
    name: string;
    rate: number;
    time: string;
  }[];
}

const ProgressBar = ({ data }: ProgressBarProps) => {
  const rebuildTime = (date: string) => {
    const list = date.split(' ');
    if (list.length > 1) {
      const dateList = list[list.length - 1].split(':');
      const hours = parseInt(dateList[0], 10) + parseInt(list[0], 10) * 24;
      return `${hours}:${dateList[1]}:${dateList[2]}`;
    }
    return date;
  };

  return (
    <>
      {data.map((element) => {
        return (
          <Component key={element.name}>
            <TitleWrap>
              <P2>{element.name}</P2>
              <P2>{rebuildTime(element.time)}</P2>
            </TitleWrap>
            <BarWrap>
              <ProgressData $rate={element.rate} />
            </BarWrap>
          </Component>
        );
      })}
    </>
  );
};

export default ProgressBar;
