import axios from 'axios';
import config from '../../config-api.json';
import {
  DeletePageClickStaticsResponse,
  GetPageMouseStaticsParamType,
  GetPageMouseStaticsResponseType,
  GetPageElementDataParamType,
  GetPageElementDataResponseType,
  GetEffectiveClickRateParamType,
  GetEffectiveClickRateResponseType,
  GetPageAttentionScreensCountParamType,
  GetPageAttentionScreensCountResponseType,
  GetPageDurationParamType,
  GetPageDurationResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;
const { tenant } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetPageMouseStatics(
  info: GetPageMouseStaticsParamType
) {
  const response = await axios.get<GetPageMouseStaticsResponseType>(
    `${apiInfo.api_url}/page_click_statics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
        page_id: info.page_id,
        event_status: info.event_status,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiDeletePageClickStatics() {
  const response = await axios.delete<DeletePageClickStaticsResponse>(
    `${apiInfo.api_url}/save_click_events/`,
    {
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// element data
export async function apiGetPageElementData(info: GetPageElementDataParamType) {
  const response = await axios.get<GetPageElementDataResponseType>(
    `${apiInfo.api_url}/page_elements/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
        page_id: info.page_id,
        event_status: info.event_status,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetEffectiveClickRate(
  info: GetEffectiveClickRateParamType
) {
  const response = await axios.get<GetEffectiveClickRateResponseType>(
    `${apiInfo.api_url}/page_elements_click/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
        page_id: info.page_id,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetPageAttentionScreensCount(
  info: GetPageAttentionScreensCountParamType
) {
  const response = await axios.get<GetPageAttentionScreensCountResponseType>(
    `${apiInfo.api_url}/page_attention_statistics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
        page_id: info.page_id,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetPageDuration(info: GetPageDurationParamType) {
  const response = await axios.get<GetPageDurationResponseType>(
    `${apiInfo.api_url}/page_duration_statistics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
        page_id: info.page_id,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}
