import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

// redux and api
import { RootState } from '../../../modules';
import {
  GetGlobalBrowserEnvStaticsParamType,
  GetGlobalDeviceEnvStaticsParamType,
  GetGlobalOSEnvStaticsParamType,
  GetGlobalSessionEnvStatisticsParamType,
  GetNationalBrowserEnvStaticsParamType,
  GetNationalDeviceEnvStaticsParamType,
  GetNationalOSEnvStaticsParamType,
  GetNationalSessionEnvStatisticsParamType,
} from '../../../api/serviceAnalytics/environmentAnalysis/type';
import {
  getGlobalBrowserEnvStaticsAsync,
  getGlobalDeviceEnvStaticsAsync,
  getGlobalOSEnvStaticsAsync,
  getGlobalSessionEnvStatisticsAsync,
  getNationalBrowserEnvStaticsAsync,
  getNationalDeviceEnvStaticsAsync,
  getNationalOSEnvStaticsAsync,
  getNationalSessionEnvStatisticsAsync,
} from '../../../modules/serviceAnalytics/environmentAnalysis/actions';

// component

import LocalMapChartCard from '../../../components/Organisms/Card/LocalMapChartCard';
import GeoMapTreeMapCard from '../../../components/Organisms/Card/GeoMapTreeMapCard';
import TreemapChartCard from '../../../components/Organisms/Card/TreemapChartCard';
import DounutChartCard from '../../../components/Organisms/Card/DounutChartCard';
import Header from '../../../components/Organisms/Header';
import ContentsLayout from '../../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../../components/Atoms/Layout/Contents/ContentsItemLayout';

const EnvironmentAnalysis = () => {
  // redux
  const dispatch = useDispatch();
  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );
  const {
    nationalSessionEnvData,
    nationalDeviceEnvData,
    nationalOSEnvData,
    nationalBrowserEnvData,
    // globalSessionEnvData,
    // globalDeviceEnvData,
    // globalOSEnvData,
    // globalBrowserEnvData,
    // isLoadingGlobal,
    isLoadingNational,
    // isLoadingGlobalBrowser,
    // isLoadingGlobalDevice,
    // isLoadingGlobalOS,
    isLoadingNationalBrowser,
    isLoadingNationalDevice,
    isLoadingNationalOS,
  } = useSelector((state: RootState) => state.environmentAnalysis);

  const onGetNationalSessionEnvStatistics = (
    info: GetNationalSessionEnvStatisticsParamType
  ) => {
    dispatch(getNationalSessionEnvStatisticsAsync.request(info));
  };
  const onGetNationalDeviceEnvStatics = (
    info: GetNationalDeviceEnvStaticsParamType
  ) => {
    dispatch(getNationalDeviceEnvStaticsAsync.request(info));
  };
  const onGetNationalOSEnvStatics = (
    info: GetNationalOSEnvStaticsParamType
  ) => {
    dispatch(getNationalOSEnvStaticsAsync.request(info));
  };
  const onGetNationalBrowserEnvStatics = (
    info: GetNationalBrowserEnvStaticsParamType
  ) => {
    dispatch(getNationalBrowserEnvStaticsAsync.request(info));
  };
  const onGetGlobalSessionEnvStatistics = (
    info: GetGlobalSessionEnvStatisticsParamType
  ) => {
    dispatch(getGlobalSessionEnvStatisticsAsync.request(info));
  };
  const onGetGlobalDeviceEnvStatics = (
    info: GetGlobalDeviceEnvStaticsParamType
  ) => {
    dispatch(getGlobalDeviceEnvStaticsAsync.request(info));
  };
  const onGetGlobalOSEnvStatics = (info: GetGlobalOSEnvStaticsParamType) => {
    dispatch(getGlobalOSEnvStaticsAsync.request(info));
  };
  const onGetGlobalBrowserEnvStatics = (
    info: GetGlobalBrowserEnvStaticsParamType
  ) => {
    dispatch(getGlobalBrowserEnvStaticsAsync.request(info));
  };
  // state
  const ContentsLayoutEl = useRef<HTMLDivElement>(null);
  const [isCountry, setIsCountry] = useState(false);
  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    const { current } = ContentsLayoutEl;
    if (current) {
      const MainContentInnerEl = document.getElementById('main-content-inner');
      if (MainContentInnerEl) {
        MainContentInnerEl.addEventListener(
          'scroll',
          () => {
            setIsScroll(true);
          },
          { once: true }
        );
      }
    }
  }, [ContentsLayoutEl.current]);

  useEffect(() => {
    const start_date = '2023-01-01';
    const end_date = '2023-01-07';
    // const start_date = moment(startDate).format('YYYY-MM-DD');
    // const end_date = moment(endDate).format('YYYY-MM-DD');
    if (!isDatePickerToggle) {
      if (isCountry) {
        onGetGlobalSessionEnvStatistics({
          start_date,
          end_date,
        });
        onGetGlobalDeviceEnvStatics({
          start_date,
          end_date,
        });
        onGetGlobalOSEnvStatics({
          start_date,
          end_date,
        });
        onGetGlobalBrowserEnvStatics({
          start_date,
          end_date,
        });
      } else {
        onGetNationalSessionEnvStatistics({
          start_date,
          end_date,
        });
        onGetNationalDeviceEnvStatics({
          start_date,
          end_date,
        });
        onGetNationalOSEnvStatics({
          start_date,
          end_date,
        });
        onGetNationalBrowserEnvStatics({
          start_date,
          end_date,
        });
      }
    }
  }, [isDatePickerToggle, startDate, endDate, isCountry]);
  return (
    <ContentsLayout ContentsLayoutRef={ContentsLayoutEl}>
      <Header title="사용 환경 분석" isDatePicker={false} />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <GeoMapTreeMapCard
            LocalMapChartCard={
              <LocalMapChartCard
                data={nationalSessionEnvData}
                isLoading={isLoadingNational}
              />
            }
            TreeMapChartCard={
              <TreemapChartCard
                data={nationalSessionEnvData}
                isLoading={isLoadingNational}
              />
            }
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={4}>
          <DounutChartCard
            data={nationalDeviceEnvData}
            title="디바이스"
            isLoading={isLoadingNationalDevice}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={4}>
          <DounutChartCard
            data={nationalOSEnvData}
            title="OS"
            isLoading={isLoadingNationalOS}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={4}>
          <DounutChartCard
            data={nationalBrowserEnvData}
            title="브라우저"
            isLoading={isLoadingNationalBrowser}
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default EnvironmentAnalysis;
