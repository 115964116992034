import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import CardHeader from '../../../Molecule/Heading/Card/CardHeader';
import { P2, P1, P3 } from '../../../Atoms/Typo';
import { IconClock, IconPage, IconShop } from '../../../Atoms/Icon';
import { GetFutureDemandResponseType } from '../../../../api/userData/type';
import Spinner from '../../../Molecule/Spinner';

const Compoent = styled(CardLayout)`
  margin: 0px 0px 20px;
  padding: 0px;
  display: flex;
  flex-direction: row;
`;

const Body = styled.div`
  width: 100%;
  display: flex;
`;

const Header = styled(CardHeader)`
  margin-top: 24px;
`;

const CardBody = styled(CardBodyLayout)`
  padding-bottom: 24px;
`;

const ReportSummaryWrap = styled.div`
  ${P1} {
    font-size: 20px;
    font-weight: normal;
    color: ${(props) => props.theme.colors.black};
  }
`;

const ReportSummaryRaw = styled.div`
  ${P1} {
    font-size: 20px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.orange5};
  }
`;

const LeftGrid = styled.div`
  width: 30%;
  margin-left: 24px;
  border-right: solid 1px ${(props) => props.theme.colors.grey200};
`;

const RightGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin-right: 24px;
  padding: 24px 0px;
`;

const ReportItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 24px;
  ${P2} {
    font-weight: bold;
    margin: 10px 0px 5px;
  }
`;

interface FutureDemandCardProps {
  loading?: boolean;
  data: GetFutureDemandResponseType['payload'] | null;
}

const FutureDemandCard = ({ loading, data }: FutureDemandCardProps) => {
  const [pageCnt, setPageCnt] = useState<number>(0);
  const [pageRate, setPageRate] = useState<number>(0);
  const [purchaseCnt, setPurchaseCnt] = useState<number>(0);
  const [purchaseRate, setPurchaseRate] = useState<number>(0);
  const [summary, setSummary] = useState('');

  const [duration, setDuration] = useState('');

  const durationVal = (cnt: string, rate: number) => {
    const cntSpl = cnt.split(' ');
    if (cntSpl.length > 1) {
      const val = parseInt(cntSpl[0], 10);
      const abs_val = Math.abs(val) * 24;

      const lastIdx = cntSpl[2].split(':');
      const time = parseInt(lastIdx[0], 10) + abs_val;
      lastIdx[0] = time.toString();

      if (rate < 0) {
        setDuration(
          `${lastIdx[0]}시간 ${lastIdx[1]}분 ${lastIdx[2]}초 (${Math.abs(
            rate
          )}%) 감소`
        );
      } else {
        setDuration(
          `${lastIdx[0]}시간 ${lastIdx[1]}분 ${lastIdx[2]}초 (${rate}%) 증가`
        );
      }
    } else {
      const list = cnt.split(':');
      if (rate < 0) {
        const hours = Math.abs(parseInt(list[0], 10));
        setDuration(
          `${hours}시간 ${list[1]}분 ${list[2]}초 (${Math.abs(rate)}%) 감소`
        );
      } else
        setDuration(`${list[0]}시간 ${list[1]}분 ${list[2]}초 (${rate}%) 증가`);
    }
  };

  useEffect(() => {
    if (data) {
      setPageCnt(data.page);
      setPageRate(data.page_rate);
      setPurchaseCnt(data.purchase);
      setPurchaseRate(data.purchase_rate);
      switch (data.expectation) {
        case 1:
          setSummary('더 자세히 비교할 것');
          break;
        case 2:
          setSummary('특정 상품을 콕 집어서 구매할 것');
          break;
        case 3:
          setSummary('페이지 이동이 더 증가할 것');
          break;
        case 4:
          setSummary('할인 관련 페이지 이동이 증가할 것');
          break;
        case 5:
          setSummary('아직 데이터를 모으고 있습니다.');
          break;

        default:
          break;
      }

      durationVal(data.duration, data.duration_rate);
    }
  }, [data]);

  return (
    <Compoent>
      {loading ? (
        <Spinner />
      ) : (
        <Body>
          <LeftGrid>
            <CardHeaderLayout>
              <Header title="미래 수요" />
            </CardHeaderLayout>
            <CardBody>
              {data && data.expectation === 5 ? (
                <ReportSummaryWrap>
                  <P1>{summary}</P1>
                </ReportSummaryWrap>
              ) : (
                <ReportSummaryWrap>
                  <P1>다음주에는</P1>
                  <ReportSummaryRaw>
                    <P1>{summary}</P1>
                  </ReportSummaryRaw>
                  <P1>으로 예상 됩니다.</P1>
                </ReportSummaryWrap>
              )}
            </CardBody>
          </LeftGrid>
          <RightGrid>
            <ReportItemWrap>
              <IconPage />
              <P2>방문 페이지</P2>
              {pageRate < 0 ? (
                <P3>
                  {Math.abs(pageCnt)}건 ({Math.abs(pageRate)}%) 감소
                </P3>
              ) : (
                <P3>
                  {pageCnt}건 ({pageRate}%) 증가
                </P3>
              )}
            </ReportItemWrap>
            <ReportItemWrap>
              <IconClock />
              <P2>페이지 체류 시간</P2>
              <P3>{duration}</P3>
            </ReportItemWrap>
            <ReportItemWrap>
              <IconShop />
              <P2>상품 구매</P2>
              {purchaseRate < 0 ? (
                <P3>
                  {Math.abs(purchaseCnt)}건 ({Math.abs(purchaseRate)}%) 감소
                </P3>
              ) : (
                <P3>
                  {purchaseCnt}건 ({purchaseRate}%) 증가
                </P3>
              )}
            </ReportItemWrap>
          </RightGrid>
        </Body>
      )}
    </Compoent>
  );
};

export default FutureDemandCard;
