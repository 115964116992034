import React from 'react';
import styled, { css } from 'styled-components';

import { GetPurchaseActivityResponseType } from '../../../../api/userData/type';

import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import CardHeader from '../../../Molecule/Heading/Card/CardHeader';
import { P2, P3 } from '../../../Atoms/Typo';
import Spinner from '../../../Molecule/Spinner';

const Compoent = styled(CardLayout)`
  margin: 0px 0px 20px;
  display: flex;
  padding: 24px 0px 0px 0px;
  ${P2} {
    color: ${(props) => props.theme.colors.black};
    font-weight: bold;
  }
  ${P3} {
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 10px;
    font-size: 16px;
  }
`;

const Header = styled(CardHeader)`
  padding-left: 24px;
`;

const CardBody = styled(CardBodyLayout)`
  display: flex;
  flex-direction: row;
  padding: 0px;
  padding-left: 14px;
`;

const Grid = styled.div<{ $last?: boolean }>`
  width: 33.33%;
  border-right: solid 1px ${(props) => props.theme.colors.grey200};
  padding-left: 10px;

  ${(props) => {
    if (props.$last) {
      return css`
        border-right: none;
      `;
    }
    return css``;
  }}
`;

const DataWrap = styled.div`
  margin: 10px 0px;
`;

const ImageWrapper = styled.div`
  display: flex;
`;

const Item = styled.div`
  width: 50%;
  margin-right: 10px;
  img {
    width: 100%;
    margin: 10px 0px;
  }
  ${P3} {
    overflow: hidden;
    height: 45px;
  }
  :hover {
    ${P3} {
      color: ${(props) => props.theme.colors.orange5};
    }
  }
`;

interface PurchaseActivityCardProps {
  isLoading?: boolean;
  purchaseActData: GetPurchaseActivityResponseType['payload'] | null;
}

const PurchaseActivityCard = ({
  isLoading,
  purchaseActData,
}: PurchaseActivityCardProps) => {
  const printCard = () => {
    if (purchaseActData) {
      return (
        <CardBody>
          <Grid>
            <DataWrap>
              <P2>클릭한 상품</P2>
              <P3>{purchaseActData.click_cnt}건</P3>
              <P2>가장 많이 클릭한 상품</P2>
              <ImageWrapper>
                {purchaseActData.click_item.map((element) => {
                  return (
                    <Item key={element.item_name}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={element.page_location}
                      >
                        <img alt="" src={element.image_url} />
                        <P3>{element.item_name}</P3>
                      </a>
                    </Item>
                  );
                })}
              </ImageWrapper>
            </DataWrap>
          </Grid>

          <Grid>
            <DataWrap>
              <P2>장바구니 담긴 상품</P2>
              <P3>{purchaseActData.cart_cnt}건</P3>
              <P2>가장 많이 담은 상품</P2>
              <ImageWrapper>
                {purchaseActData.cart_item.map((element) => {
                  return (
                    <Item key={element.item_name}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={element.page_location}
                      >
                        <img alt="" src={element.image_url} />
                        <P3>{element.item_name}</P3>
                      </a>
                    </Item>
                  );
                })}
              </ImageWrapper>
            </DataWrap>
          </Grid>

          <Grid $last>
            <DataWrap>
              <P2>구매한 상품</P2>
              <P3>{purchaseActData.purchase_cnt}건</P3>
              <P2>가장 많이 구매한 상품</P2>
              <ImageWrapper>
                {purchaseActData.purchase_item.map((element) => {
                  return (
                    <Item key={element.item_name}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={element.page_location}
                      >
                        <img alt="" src={element.image_url} />
                        <P3>{element.item_name}</P3>
                      </a>
                    </Item>
                  );
                })}
              </ImageWrapper>
            </DataWrap>
          </Grid>
        </CardBody>
      );
    }
    return <CardBody>데이터가 없습니다.</CardBody>;
  };

  return (
    <Compoent>
      <CardHeaderLayout>
        <Header title="구매 활동" />
      </CardHeaderLayout>
      {isLoading ? <Spinner /> : printCard()}
    </Compoent>
  );
};

export default PurchaseActivityCard;
