import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { P3 } from '../Typo';

const Component = styled(NavLink)`
  position: relative;
  border-radius: 5px;
  ${P3} {
    color: ${(props) => props.theme.colors.grey800};
  }

  &.active {
    ${P3} {
      color: #008ded;
    }

    background-color: #e7f3fd;
    ::before {
      content: '';
      position: absolute;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      height: 60%;
      width: 5px;
      background-color: #008ded;
      left: 0px;
    }
  }
  :hover {
    ${P3} {
      color: #008ded;
    }
  }
`;

interface DefaultNavLinkProps {
  to: string;
  title: string;
  exact?: boolean;
  children?: React.ReactNode;
}

const DefaultNavLink = ({
  to,
  title,
  exact,
  children,
}: DefaultNavLinkProps) => {
  return (
    <Component exact={exact !== undefined ? exact : true} to={to}>
      {children}
      <P3>{title}</P3>
    </Component>
  );
};

export default DefaultNavLink;
