import axios from 'axios';
import config from '../../config-api.json';
import {
  GetGlobalSessionEnvStatisticsParamType,
  GetGlobalSessionEnvStatisticsResponseType,
  GetNationalSessionEnvStatisticsParamType,
  GetNationalSessionEnvStatisticsResponseType,
  GetNationalDeviceEnvStaticsParamType,
  GetNationalDeviceEnvStaticsResponseType,
  GetNationalOSEnvStaticsParamType,
  GetNationalOSEnvStaticsResponseType,
  GetNationalBrowserEnvStaticsParamType,
  GetNationalBrowserEnvStaticsResponseType,
  GetGlobalDeviceEnvStaticsParamType,
  GetGlobalDeviceEnvStaticsResponseType,
  GetGlobalOSEnvStaticsParamType,
  GetGlobalOSEnvStaticsResponseType,
  GetGlobalBrowserEnvStaticsParamType,
  GetGlobalBrowserEnvStaticsResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;
const { tenant } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetNationalSessionEnvStatistics(
  info: GetNationalSessionEnvStatisticsParamType
) {
  const response = await axios.get<GetNationalSessionEnvStatisticsResponseType>(
    `${apiInfo.api_url}/national_session_env_statics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetNationalDeviceEnvStatics(
  info: GetNationalDeviceEnvStaticsParamType
) {
  const response = await axios.get<GetNationalDeviceEnvStaticsResponseType>(
    `${apiInfo.api_url}/national_device_env_statics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetNationalOSEnvStatics(
  info: GetNationalOSEnvStaticsParamType
) {
  const response = await axios.get<GetNationalOSEnvStaticsResponseType>(
    `${apiInfo.api_url}/national_os_env_statics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetNationalBrowserEnvStatics(
  info: GetNationalBrowserEnvStaticsParamType
) {
  const response = await axios.get<GetNationalBrowserEnvStaticsResponseType>(
    `${apiInfo.api_url}/national_browser_env_statics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetGlobalSessionEnvStatistics(
  info: GetGlobalSessionEnvStatisticsParamType
) {
  const response = await axios.get<GetGlobalSessionEnvStatisticsResponseType>(
    `${apiInfo.api_url}/global_session_env_statics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetGlobalDeviceEnvStatics(
  info: GetGlobalDeviceEnvStaticsParamType
) {
  const response = await axios.get<GetGlobalDeviceEnvStaticsResponseType>(
    `${apiInfo.api_url}/global_device_env_statics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetGlobalOSEnvStatics(
  info: GetGlobalOSEnvStaticsParamType
) {
  const response = await axios.get<GetGlobalOSEnvStaticsResponseType>(
    `${apiInfo.api_url}/global_os_env_statics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetGlobalBrowserEnvStatics(
  info: GetGlobalBrowserEnvStaticsParamType
) {
  const response = await axios.get<GetGlobalBrowserEnvStaticsResponseType>(
    `${apiInfo.api_url}/global_browser_env_statics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}
