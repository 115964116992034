import axios from 'axios';
import config from '../../../config-api.json';

import { GetScrollEventsParamType, GetScrollEventsResponseType } from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;
const { tenant } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetScrollEvents(info: GetScrollEventsParamType) {
  const response = await axios.get<GetScrollEventsResponseType>(
    `${apiInfo.api_url}/scroll_events/`,
    {
      params: {
        page_id: info.page_id,
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export { apiGetScrollEvents as default };
