import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../modules';

// Private Public components
import NotfoundPage from '../../components/NotfoundPage';

// Private components
import PrivateRoute from '../_PrivateRoute';
import ServiceDashboard from '../ServiceAnalytics/Dashboard';
import ServiceEnvironmentAnalysis from '../ServiceAnalytics/EnvironmentAnalysis';

import PageUXAnalysisList from '../PageAnalytics/PageList/PageUXAnalysis';
import UXAnalysis from '../PageAnalytics/UXAnalysis';
import UsersData from '../UserData';
import UserList from '../UserList';

const env = process.env.NODE_ENV;
let isDevelopment = false;
if (env === 'development') {
  isDevelopment = true;
}
const Routes = () => {
  // redux
  const { isLogin } = useSelector((state: RootState) => state.authentication);

  return (
    <>
      <Switch>
        <PrivateRoute
          exact
          path="/serviceAnalytics"
          title="서비스 이용 분석"
          Component={ServiceDashboard}
        />
        <PrivateRoute
          exact
          path="/serviceAnalytics/environmentAnalysis"
          title="사용환경 분석"
          Component={ServiceEnvironmentAnalysis}
        />
        <PrivateRoute
          exact
          path="/pageAnalytics/uxAnalysisList"
          title="페이지 UX 분석"
          Component={PageUXAnalysisList}
        />
        <PrivateRoute
          exact
          path="/pageAnalytics/uxAnalysisList/uxAnalysis"
          title="UX 분석"
          Component={UXAnalysis}
        />
        <PrivateRoute
          exact
          path="/userData"
          title="사용자 데이터"
          Component={UserList}
        />
        <PrivateRoute
          exact
          path="/userData/detail"
          title="사용자 데이터"
          Component={UsersData}
        />

        <PrivateRoute
          exact
          path="*"
          title="페이지 이용 분석"
          Component={ServiceDashboard}
        />

        <Route component={NotfoundPage} />
      </Switch>
    </>
  );
};

export default Routes;
