import React from 'react';
import styled from 'styled-components';
import { IconProfile } from '../../../Atoms/Icon';

import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import { P1 } from '../../../Atoms/Typo';
import DefaultCardHeading from '../../../Molecule/Heading/Card/DefaultCardHeading';

const Compoent = styled(CardLayout)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 24px;
`;

const ProfileWrap = styled.div`
  ${P1} {
    font-size: 20px;
    font-weight: normal;
    color: ${(props) => props.theme.colors.black};
  }
`;

interface UserIndexCardProps {
  userIndex: string;
}

const UserIndexCard = ({ userIndex }: UserIndexCardProps) => {
  return (
    <Compoent>
      <IconProfile />
      <ProfileWrap>
        <CardHeaderLayout>
          <DefaultCardHeading title="User Index" />
        </CardHeaderLayout>
        <CardBodyLayout>
          <P1>{userIndex}</P1>
        </CardBodyLayout>
      </ProfileWrap>
    </Compoent>
  );
};

export default UserIndexCard;
