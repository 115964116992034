import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import CardHeader from '../../../Molecule/Heading/Card/CardHeader';
import { P2 } from '../../../Atoms/Typo';
import Spinner from '../../../Molecule/Spinner';
import { GetTimelineResponseType } from '../../../../api/userData/type';

const Compoent = styled(CardLayout)`
  margin: 0px 0px 20px;
`;

const CardBody = styled(CardBodyLayout)`
  ${P2} {
    color: ${(props) => props.theme.colors.black};
    font-weight: bold;
    :nth-child(2n) {
      font-weight: normal;
      margin-bottom: 24px;
    }
  }
`;

interface UserDataDefaultCardProps {
  isLoading?: boolean;
  title: string;
  data: GetTimelineResponseType['payload'] | null;
}

const UserDataDefaultCard = ({
  isLoading,
  title,
  data,
}: UserDataDefaultCardProps) => {
  const [timeline, setTimeline] = useState('');
  const [activityDay, setActivityDay] = useState('');

  useEffect(() => {
    if (data) {
      setTimeline(
        `${data.main_timeline.start_time} ~ ${data.main_timeline.end_time}`
      );
      setActivityDay(
        `${data.activity_day[0]} > ${data.activity_day[1]} > ${data.activity_day[2]}`
      );
    }
  }, [data]);

  return (
    <Compoent>
      <CardHeaderLayout>
        <CardHeader title={title} />
      </CardHeaderLayout>
      {isLoading ? (
        <Spinner />
      ) : (
        <CardBody>
          <P2>주 활동 시간대</P2>
          <P2>{timeline}</P2>
          <P2>주 활동 요일</P2>
          <P2>{activityDay}</P2>
        </CardBody>
      )}
    </Compoent>
  );
};

export default UserDataDefaultCard;
