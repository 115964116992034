import { createReducer } from 'typesafe-actions';
import {
  GET_BASIC_INFO,
  GET_BASIC_INFO_ERROR,
  GET_BASIC_INFO_SUCCESS,
  GET_BROWSER_INFO,
  GET_BROWSER_INFO_ERROR,
  GET_BROWSER_INFO_SUCCESS,
  GET_DEVICE_INFO,
  GET_DEVICE_INFO_ERROR,
  GET_DEVICE_INFO_SUCCESS,
  GET_OS_INFO,
  GET_OS_INFO_ERROR,
  GET_OS_INFO_SUCCESS,
  GET_USER_INFO,
  GET_USER_INFO_ERROR,
  GET_USER_INFO_SUCCESS,
  GET_PURCHASE_ACTIVITY,
  GET_PURCHASE_ACTIVITY_ERROR,
  GET_PURCHASE_ACTIVITY_SUCCESS,
  SET_ERROR_NULL_USER_DATA,
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_ERROR,
  GET_PAGE_ACTIVITY,
  GET_PAGE_ACTIVITY_SUCCESS,
  GET_PAGE_ACTIVITY_ERROR,
  GET_SESSION_INFO,
  GET_SESSION_INFO_SUCCESS,
  GET_SESSION_INFO_ERROR,
  GET_FUTURE_DEMAND,
  GET_FUTURE_DEMAND_SUCCESS,
  GET_FUTURE_DEMAND_ERROR,
  GET_TIMELINE,
  GET_TIMELINE_SUCCESS,
  GET_TIMELINE_ERROR,
  GET_USER_PREFERENCE,
  GET_USER_PREFERENCE_SUCCESS,
  GET_USER_PREFERENCE_ERROR,
  CHANGE_PAGE,
} from './actions';
import { userDataAction, userDataState } from './types';

const initialState: userDataState = {
  pageNum: 1,
  isBasicInfoLoading: false,
  isBrowserInfoLoading: false,
  isDeviceInfoLoading: false,
  isOSInfoLoading: false,
  isUserInfoLoading: false,
  isPurchaseActLoading: false,
  isUserListLoading: false,
  isPageActLoading: false,
  isSessionLoading: false,
  isFutureLoading: false,
  isTimelineLoading: false,
  isPreferenceLoading: false,

  userListData: [],
  basicInfoData: null,
  userInfoData: null,
  OSInfoData: null,
  browserInfoData: null,
  deviceInfoData: null,
  purchaseActData: null,
  pageActData: null,
  sessionInfo: [],
  futureData: null,
  timelineData: null,
  preferenceData: [],

  userDataError: null,
};

const reducer = createReducer<userDataState, userDataAction>(initialState, {
  // paging
  [CHANGE_PAGE]: (state, action) => ({
    ...state,
    pageNum: action.payload,
  }),

  [GET_USER_LIST]: (state) => ({
    ...state,
    isUserListLoading: true,
    userListData: [],
    userDataError: null,
  }),
  [GET_USER_LIST_SUCCESS]: (state, action) => ({
    ...state,
    isUserListLoading: false,
    userListData: action.payload.payload,
  }),
  [GET_USER_LIST_ERROR]: (state, action) => ({
    ...state,
    isUserListLoading: false,
    userListData: [],
    userDataError: action.payload,
  }),

  [GET_BASIC_INFO]: (state) => ({
    ...state,
    isBasicInfoLoading: true,
    basicInfoData: null,
    userDataError: null,
  }),
  [GET_BASIC_INFO_SUCCESS]: (state, action) => ({
    ...state,
    isBasicInfoLoading: false,
    basicInfoData: action.payload.payload,
  }),
  [GET_BASIC_INFO_ERROR]: (state, action) => ({
    ...state,
    isBasicInfoLoading: false,
    basicInfoData: null,
    userDataError: action.payload,
  }),

  [GET_USER_INFO]: (state) => ({
    ...state,
    isUserInfoLoading: true,
    userInfoData: null,
    userDataError: null,
  }),
  [GET_USER_INFO_SUCCESS]: (state, action) => ({
    ...state,
    isUserInfoLoading: false,
    userInfoData: action.payload.payload,
  }),
  [GET_USER_INFO_ERROR]: (state, action) => ({
    ...state,
    isUserInfoLoading: false,
    userInfoData: null,
    userDataError: action.payload,
  }),

  [GET_OS_INFO]: (state) => ({
    ...state,
    isOSInfoLoading: true,
    OSInfoData: null,
    userDataError: null,
  }),
  [GET_OS_INFO_SUCCESS]: (state, action) => ({
    ...state,
    isOSInfoLoading: false,
    OSInfoData: action.payload.payload,
  }),
  [GET_OS_INFO_ERROR]: (state, action) => ({
    ...state,
    isOSInfoLoading: false,
    OSInfoData: null,
    userDataError: action.payload,
  }),

  [GET_BROWSER_INFO]: (state) => ({
    ...state,
    isBrowserInfoLoading: true,
    browserInfoData: null,
    userDataError: null,
  }),
  [GET_BROWSER_INFO_SUCCESS]: (state, action) => ({
    ...state,
    isBrowserInfoLoading: false,
    browserInfoData: action.payload.payload,
  }),
  [GET_BROWSER_INFO_ERROR]: (state, action) => ({
    ...state,
    isBrowserInfoLoading: false,
    browserInfoData: null,
    userDataError: action.payload,
  }),

  [GET_DEVICE_INFO]: (state) => ({
    ...state,
    isDeviceInfoLoading: true,
    deviceInfoData: null,
    userDataError: null,
  }),
  [GET_DEVICE_INFO_SUCCESS]: (state, action) => ({
    ...state,
    isDeviceInfoLoading: false,
    deviceInfoData: action.payload.payload,
  }),
  [GET_DEVICE_INFO_ERROR]: (state, action) => ({
    ...state,
    isDeviceInfoLoading: false,
    deviceInfoData: null,
    userDataError: action.payload,
  }),

  [GET_PURCHASE_ACTIVITY]: (state) => ({
    ...state,
    isPurchaseActLoading: true,
    purchaseActData: null,
    userDataError: null,
  }),
  [GET_PURCHASE_ACTIVITY_SUCCESS]: (state, action) => ({
    ...state,
    isPurchaseActLoading: false,
    purchaseActData: action.payload.payload,
  }),
  [GET_PURCHASE_ACTIVITY_ERROR]: (state, action) => ({
    ...state,
    isPurchaseActLoading: false,
    purchaseActData: null,
    userDataError: action.payload,
  }),

  [GET_PAGE_ACTIVITY]: (state) => ({
    ...state,
    isPageActLoading: true,
    pageActData: null,
    userDataError: null,
  }),
  [GET_PAGE_ACTIVITY_SUCCESS]: (state, action) => ({
    ...state,
    isPageActLoading: false,
    pageActData: action.payload.payload,
  }),
  [GET_PAGE_ACTIVITY_ERROR]: (state, action) => ({
    ...state,
    isPageActLoading: false,
    pageActData: null,
    userDataError: action.payload,
  }),

  [GET_SESSION_INFO]: (state) => ({
    ...state,
    isSessionLoading: true,
    sessionInfo: [],
    userDataError: null,
  }),
  [GET_SESSION_INFO_SUCCESS]: (state, action) => ({
    ...state,
    isSessionLoading: false,
    sessionInfo: action.payload.payload,
  }),
  [GET_SESSION_INFO_ERROR]: (state, action) => ({
    ...state,
    isSessionLoading: false,
    sessionInfo: [],
    userDataError: action.payload,
  }),

  [GET_FUTURE_DEMAND]: (state) => ({
    ...state,
    isFutureLoading: true,
    futureData: null,
    userDataError: null,
  }),
  [GET_FUTURE_DEMAND_SUCCESS]: (state, action) => ({
    ...state,
    isFutureLoading: false,
    futureData: action.payload.payload,
  }),
  [GET_FUTURE_DEMAND_ERROR]: (state, action) => ({
    ...state,
    isFutureLoading: false,
    futureData: null,
    userDataError: action.payload,
  }),

  [GET_TIMELINE]: (state) => ({
    ...state,
    isTimelineLoading: true,
    timelineData: null,
    userDataError: null,
  }),
  [GET_TIMELINE_SUCCESS]: (state, action) => ({
    ...state,
    isTimelineLoading: false,
    timelineData: action.payload.payload,
  }),
  [GET_TIMELINE_ERROR]: (state, action) => ({
    ...state,
    isTimelineLoading: false,
    timelineData: null,
    userDataError: action.payload,
  }),

  [GET_USER_PREFERENCE]: (state) => ({
    ...state,
    isPreferenceLoading: true,
    preferenceData: [],
    userDataError: null,
  }),
  [GET_USER_PREFERENCE_SUCCESS]: (state, action) => ({
    ...state,
    isPreferenceLoading: false,
    preferenceData: action.payload.payload,
  }),
  [GET_USER_PREFERENCE_ERROR]: (state, action) => ({
    ...state,
    isPreferenceLoading: false,
    preferenceData: [],
    userDataError: action.payload,
  }),

  [SET_ERROR_NULL_USER_DATA]: (state) => ({
    ...state,
    userDataError: null,
  }),
});

export default reducer;
