import React, { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';
import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import CardHeader from '../../../Molecule/Heading/Card/CardHeader';
import { P2, P1, P3 } from '../../../Atoms/Typo';
import {
  GetPageActivityPayload,
  GetPageActivityResponseType,
  GetSessionInfoResponseType,
} from '../../../../api/userData/type';
import Spinner from '../../../Molecule/Spinner';
import SessionInfoTable from '../../../Molecule/SessionInfoTable';

const Compoent = styled(CardLayout)`
  display: flex;
  flex-direction: row;
  padding: 0px 24px;
`;

interface SessionInfoCardProps {
  isLoading?: boolean;
  sessionInfoData: GetSessionInfoResponseType['payload'];
}

const SessionInfoCard = ({
  isLoading,
  sessionInfoData,
}: SessionInfoCardProps) => {
  const [data, setData] = useState<GetSessionInfoResponseType['payload']>([]);

  useEffect(() => {
    if (sessionInfoData) {
      setData(sessionInfoData);
    }
  }, [sessionInfoData]);

  return (
    <CardLayout>
      <CardHeaderLayout>
        <CardHeader title="날짜별 세션 정보" />
      </CardHeaderLayout>
      {isLoading ? (
        <Spinner />
      ) : (
        <CardBodyLayout>
          <SessionInfoTable data={data} />
        </CardBodyLayout>
      )}
    </CardLayout>
  );
};

export default SessionInfoCard;
