import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import CardHeader from '../../../Molecule/Heading/Card/CardHeader';
import { P2, P1, P3 } from '../../../Atoms/Typo';
import {
  IconBeauty,
  IconDigital,
  IconFashion,
  IconFood,
  IconInterior,
  IconLiving,
  IconSports,
  IconToys,
} from '../../../Atoms/Icon';
import { GetUserPreferenceResponseType } from '../../../../api/userData/type';
import Spinner from '../../../Molecule/Spinner';

const Compoent = styled(CardLayout)`
  margin: 0px 0px 20px;
  padding: 0px;
  display: flex;
  flex-direction: row;
`;

const Body = styled.div`
  width: 100%;
  display: flex;
`;

const Header = styled(CardHeader)`
  margin-top: 24px;
`;

const CardBody = styled(CardBodyLayout)`
  padding-bottom: 24px;
`;

const ReportSummaryWrap = styled.div`
  ${P1} {
    font-size: 20px;
    font-weight: normal;
    color: ${(props) => props.theme.colors.black};
  }
`;

const ReportSummaryRaw = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: normal;
  color: ${(props) => props.theme.colors.black};
  ${P1} {
    font-size: 20px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.orange5};
  }
`;

const LeftGrid = styled.div`
  width: 30%;
  margin-left: 24px;
  border-right: solid 1px ${(props) => props.theme.colors.grey200};
`;

const RightGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin-right: 24px;
  padding: 24px 0px;
`;

const ReportItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 24px;
  ${P2} {
    font-weight: bold;
    margin: 10px 0px 5px;
  }
`;

interface UserPreferenceCardProps {
  loading?: boolean;
  data: GetUserPreferenceResponseType['payload'];
}

const UserPreferenceCard = ({ loading, data }: UserPreferenceCardProps) => {
  const [summary, setSummary] = useState('');

  const printIcon = (type: string) => {
    switch (type) {
      case '가구/인테리어':
        return <IconInterior />;
      case '디지털/가전':
        return <IconDigital />;
      case '가공식품':
        return <IconFood />;
      case '스포츠/아웃도어':
        return <IconSports />;
      case '주방/생활':
        return <IconLiving />;
      case '유아동/출산/완구':
        return <IconToys />;
      case '패션잡화/명품/뷰티':
        return <IconBeauty />;
      case '패션의류':
        return <IconFashion />;

      default:
        return <IconToys />;
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      setSummary(data[0].name);
    }
  }, [data]);

  return (
    <Compoent>
      {loading ? (
        <Spinner />
      ) : (
        <Body>
          <LeftGrid>
            <CardHeaderLayout>
              <Header title="고객 취향" />
            </CardHeaderLayout>
            <CardBody>
              <ReportSummaryWrap>
                <P1>본 사용자님은</P1>
                <ReportSummaryRaw>
                  <P1>{summary}</P1>에 관심이
                </ReportSummaryRaw>
                <P1>많은 것으로 보입니다.</P1>
              </ReportSummaryWrap>
            </CardBody>
          </LeftGrid>
          <RightGrid>
            {data.map((element) => {
              return (
                <ReportItemWrap key={element.name}>
                  {printIcon(element.name)}
                  <P2>{element.name}</P2>
                  {element.rate < 0 ? (
                    <P3>
                      {element.cnt}건 ({Math.abs(element.rate)}%) 감소
                    </P3>
                  ) : (
                    <P3>
                      {element.cnt}건 ({element.rate}%) 증가
                    </P3>
                  )}
                </ReportItemWrap>
              );
            })}
          </RightGrid>
        </Body>
      )}
    </Compoent>
  );
};

export default UserPreferenceCard;
