import React, { useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../modules';

import ContentsLayout from '../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../components/Atoms/Layout/Contents/ContentsItemLayout';

import Header from '../../components/Organisms/Header';
import UserListCard from '../../components/Organisms/Card/UserData/UserListCard';
import { getUserListAsync, onChangePage } from '../../modules/userData';
import { GetUserListParamType } from '../../api/userData/type';

const UserList = () => {
  const { isUserListLoading, userListData, pageNum } = useSelector(
    (state: RootState) => state.userData
  );

  const dispatch = useDispatch();

  const onGetUserList = (info: GetUserListParamType) => {
    dispatch(getUserListAsync.request(info));
  };

  const onSetPageNumber = (page: number) => {
    dispatch(onChangePage(page));
  };

  useEffect(() => {
    if (userListData.length > 0) return;
    onGetUserList({});
  }, []);

  return (
    <ContentsLayout>
      <Header title="사용자 데이터" isDatePicker={false} />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <UserListCard
            isLoading={isUserListLoading}
            userList={userListData}
            onSetPageNum={onSetPageNumber}
            pageNumber={pageNum}
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default UserList;
