import styled from 'styled-components';

export const H1 = styled.h1`
  font-weight: 900;
  font-size: 32px;
  color: ${(props) => props.theme.colors.grey800};
`;

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 24px;
  color: ${(props) => props.theme.colors.grey800};
`;

export const H3 = styled.h3`
  font-weight: 700;
  font-size: 20px;
  color: ${(props) => props.theme.colors.grey800};
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.theme.colors.grey800};
`;

export const H5 = styled.h5`
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => props.theme.colors.grey800};
`;

export const H6 = styled.h6`
  font-weight: 700;
  font-size: 12px;
  color: ${(props) => props.theme.colors.grey800};
`;

export const P1 = styled.p`
  font-weight: 400;
  font-size: 18px;
  color: ${(props) => props.theme.colors.grey800};
`;

export const P2 = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: ${(props) => props.theme.colors.grey800};
`;

export const P3 = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.grey800};
`;

export const P4 = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => props.theme.colors.grey800};
`;

export const P5 = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey800};
`;

export const DateErrorTypo = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.grey800};
`;
