import { takeLatest } from 'redux-saga/effects';
import {
  apiGetBasicInfo,
  apiGetBrowserInfo,
  apiGetDeviceInfo,
  apiGetOSInfo,
  apiGetUserInfo,
  apiGetPurchaseActivity,
  apiGetUserListInfo,
  apiGetPageActivity,
  apiGetSessionInfo,
  apiGetFutureDemand,
  apiGetTimeline,
  apiGetUserPreference,
} from '../../api/userData/api';
import {
  GET_BASIC_INFO,
  GET_BROWSER_INFO,
  GET_DEVICE_INFO,
  GET_OS_INFO,
  GET_USER_INFO,
  GET_PURCHASE_ACTIVITY,
  getBasicInfoAsync,
  getBrowserInfoAsync,
  getDeviceInfoAsync,
  getOSInfoAsync,
  getUserInfoAsync,
  getPurchaseActivityAsync,
  getUserListAsync,
  GET_USER_LIST,
  getPageActivityAsync,
  GET_PAGE_ACTIVITY,
  getSessionInfoAsync,
  GET_SESSION_INFO,
  getFutureDemandAsync,
  GET_FUTURE_DEMAND,
  getTimelineAsync,
  GET_TIMELINE,
  getUserPreferenceAsync,
  GET_USER_PREFERENCE,
} from './actions';

import createRequestSaga from '../createRequestSaga';

const GetUserListSaga = createRequestSaga(getUserListAsync, apiGetUserListInfo);

const getBasicInfoSaga = createRequestSaga(getBasicInfoAsync, apiGetBasicInfo);

const getUserInfoSaga = createRequestSaga(getUserInfoAsync, apiGetUserInfo);

const getOSInfoSaga = createRequestSaga(getOSInfoAsync, apiGetOSInfo);

const getBrowserInfoSaga = createRequestSaga(
  getBrowserInfoAsync,
  apiGetBrowserInfo
);

const getDeviceInfoSaga = createRequestSaga(
  getDeviceInfoAsync,
  apiGetDeviceInfo
);

const getPurchaseActivitySaga = createRequestSaga(
  getPurchaseActivityAsync,
  apiGetPurchaseActivity
);

const getPageActivitySaga = createRequestSaga(
  getPageActivityAsync,
  apiGetPageActivity
);

const getSessionInfoSaga = createRequestSaga(
  getSessionInfoAsync,
  apiGetSessionInfo
);

const getFutureDemandSaga = createRequestSaga(
  getFutureDemandAsync,
  apiGetFutureDemand
);

const getTimelineSaga = createRequestSaga(getTimelineAsync, apiGetTimeline);

const getUserPreferenceSaga = createRequestSaga(
  getUserPreferenceAsync,
  apiGetUserPreference
);

export function* userDataSaga() {
  yield takeLatest(GET_USER_LIST, GetUserListSaga);
  yield takeLatest(GET_BASIC_INFO, getBasicInfoSaga);
  yield takeLatest(GET_USER_INFO, getUserInfoSaga);
  yield takeLatest(GET_OS_INFO, getOSInfoSaga);
  yield takeLatest(GET_BROWSER_INFO, getBrowserInfoSaga);
  yield takeLatest(GET_DEVICE_INFO, getDeviceInfoSaga);
  yield takeLatest(GET_PURCHASE_ACTIVITY, getPurchaseActivitySaga);
  yield takeLatest(GET_PAGE_ACTIVITY, getPageActivitySaga);
  yield takeLatest(GET_SESSION_INFO, getSessionInfoSaga);
  yield takeLatest(GET_FUTURE_DEMAND, getFutureDemandSaga);
  yield takeLatest(GET_TIMELINE, getTimelineSaga);
  yield takeLatest(GET_USER_PREFERENCE, getUserPreferenceSaga);
}

export { userDataSaga as default };
