import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import CardHeader from '../../../Molecule/Heading/Card/CardHeader';
import { P2, P1 } from '../../../Atoms/Typo';
import Spinner from '../../../Molecule/Spinner';

const Compoent = styled(CardLayout)`
  margin: 0px 0px 20px;
`;

const CardBody = styled(CardBodyLayout)`
  word-break: break-all;
  ${P1} {
    font-weight: 500;
    color: ${(props) => props.theme.colors.black};
  }
`;

const Wrap = styled.div`
  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

interface UserInformCardProps {
  isLoading?: boolean;
  gender: string;
  region: string;
  os: string;
  browser: string;
  device: string;
}

const UserInformCard = ({
  isLoading,
  gender,
  region,
  os,
  browser,
  device,
}: UserInformCardProps) => {
  const [genderData, setGender] = useState('-');

  useEffect(() => {
    if (gender.length > 0) {
      setGender(gender);
    } else setGender('-');
  }, [gender]);
  return (
    <Compoent>
      <CardHeaderLayout>
        <CardHeader title="사용자 정보" />
      </CardHeaderLayout>
      {isLoading ? (
        <Spinner />
      ) : (
        <CardBody>
          <Wrap>
            <P1>성별</P1>
            <P2>{genderData}</P2>
          </Wrap>
          <Wrap>
            <P1>지역</P1>
            <P2>{region}</P2>
          </Wrap>
          <Wrap>
            <P1>OS</P1>
            <P2>{os}</P2>
          </Wrap>
          <Wrap>
            <P1>브라우저</P1>
            <P2>{browser}</P2>
          </Wrap>
          <Wrap>
            <P1>디바이스</P1>
            <P2>{device}</P2>
          </Wrap>
        </CardBody>
      )}
    </Compoent>
  );
};

export default UserInformCard;
