import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../modules';
import {
  getBasicInfoAsync,
  getBrowserInfoAsync,
  getDeviceInfoAsync,
  getFutureDemandAsync,
  getOSInfoAsync,
  getPageActivityAsync,
  getPurchaseActivityAsync,
  getSessionInfoAsync,
  getTimelineAsync,
  getUserInfoAsync,
  getUserPreferenceAsync,
} from '../../modules/userData';

import {
  GetBasicInfoParamType,
  GetBrowserInfoParamType,
  GetDeviceInfoParamType,
  GetFutureDemandParamType,
  GetOSInfoParamType,
  GetPageActivityParamType,
  GetPurchaseActivityParamType,
  GetSessionInfoParamType,
  GetTimelineParamType,
  GetUserInfoParamType,
  GetUserPreferenceParamType,
} from '../../api/userData/type';

import ContentsLayout from '../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../components/Atoms/Layout/Contents/ContentsItemLayout';

import UserDataLeftGrid from '../../components/Templates/UserData/UserDataLeftGrid';
import UserDataRightGrid from '../../components/Templates/UserData/UserDataRightGrid';
import UserIndexCard from '../../components/Organisms/Card/UserData/UserIndexCard';
import DefaultLink from '../../components/Atoms/Link';
import { IconBack } from '../../components/Atoms/Icon';

interface UsersDataProps extends RouteComponentProps {}

const UsersData = ({ location }: UsersDataProps) => {
  const [userIndex, setUserIndex] = useState('');

  const {
    isBasicInfoLoading,
    isBrowserInfoLoading,
    isDeviceInfoLoading,
    isOSInfoLoading,
    isPurchaseActLoading,
    isUserInfoLoading,
    isPageActLoading,
    isSessionLoading,
    isFutureLoading,
    isTimelineLoading,
    isPreferenceLoading,

    basicInfoData,
    userInfoData,
    OSInfoData,
    browserInfoData,
    deviceInfoData,
    purchaseActData,
    pageActData,
    sessionInfo,
    futureData,
    timelineData,
    preferenceData,
  } = useSelector((state: RootState) => state.userData);

  const dispatch = useDispatch();

  const onGetBasicInfo = (info: GetBasicInfoParamType) => {
    dispatch(getBasicInfoAsync.request(info));
  };
  const onGetUserInfo = (info: GetUserInfoParamType) => {
    dispatch(getUserInfoAsync.request(info));
  };
  const onGetOSInfo = (info: GetOSInfoParamType) => {
    dispatch(getOSInfoAsync.request(info));
  };
  const onGetBrowserInfo = (info: GetBrowserInfoParamType) => {
    dispatch(getBrowserInfoAsync.request(info));
  };
  const onGetDeviceInfo = (info: GetDeviceInfoParamType) => {
    dispatch(getDeviceInfoAsync.request(info));
  };
  const onGetPurchaseActivity = (info: GetPurchaseActivityParamType) => {
    dispatch(getPurchaseActivityAsync.request(info));
  };
  const onGetPageActivity = (info: GetPageActivityParamType) => {
    dispatch(getPageActivityAsync.request(info));
  };
  const onGetSessionInfo = (info: GetSessionInfoParamType) => {
    dispatch(getSessionInfoAsync.request(info));
  };
  const onGetFutureDemand = (info: GetFutureDemandParamType) => {
    dispatch(getFutureDemandAsync.request(info));
  };
  const onGetTimeline = (info: GetTimelineParamType) => {
    dispatch(getTimelineAsync.request(info));
  };
  const onGetUserPreference = (info: GetUserPreferenceParamType) => {
    dispatch(getUserPreferenceAsync.request(info));
  };

  useEffect(() => {
    const params: { [key: string]: string } = {};
    location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      (str: string, key: string, value: string) => {
        params[key] = value;
        return value;
      }
    );

    setUserIndex(params.user_index);
  }, []);

  useEffect(() => {
    if (userIndex) {
      onGetBasicInfo({ user_index: userIndex });
      onGetUserInfo({ user_index: userIndex });
      onGetOSInfo({ user_index: userIndex });
      onGetBrowserInfo({ user_index: userIndex });
      onGetDeviceInfo({ user_index: userIndex });
      onGetPurchaseActivity({ user_index: userIndex });
      onGetPageActivity({ user_index: userIndex });
      onGetSessionInfo({ user_index: userIndex });
      onGetFutureDemand({ user_index: userIndex });
      onGetTimeline({ user_index: userIndex });
      onGetUserPreference({ user_index: userIndex });
    }
  }, [userIndex]);

  return (
    <ContentsLayout>
      <ContentBodyLayout>
        <DefaultLink to="/userData">
          <IconBack />
        </DefaultLink>
        <ContentsItemLayout desktop={12}>
          <UserIndexCard userIndex={userIndex} />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={3}>
          <UserDataLeftGrid
            basicLoading={isBasicInfoLoading}
            userLoading={isUserInfoLoading}
            osLoading={isOSInfoLoading}
            browserLoading={isBrowserInfoLoading}
            deviceLoading={isDeviceInfoLoading}
            timelineLoading={isTimelineLoading}
            basicData={basicInfoData}
            userInfoData={userInfoData}
            OSInfoData={OSInfoData}
            browserInfoData={browserInfoData}
            deviceInfoData={deviceInfoData}
            timelineData={timelineData}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={9}>
          <UserDataRightGrid
            purchaseLoading={isPurchaseActLoading}
            purchaseActData={purchaseActData}
            pageActLoading={isPageActLoading}
            futureLoading={isFutureLoading}
            preferenceLoading={isPreferenceLoading}
            pageActData={pageActData}
            sessionLoading={isSessionLoading}
            sessionData={sessionInfo}
            futureData={futureData}
            preferenceData={preferenceData}
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default withRouter(UsersData);
