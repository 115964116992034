import axios from 'axios';
import config from '../../../config-api.json';

import {
  GetPageElementsFlowParamType,
  GetPageElementsFlowResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;
const { tenant } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetPageElementsFlow(
  info: GetPageElementsFlowParamType
) {
  const response = await axios.get<GetPageElementsFlowResponseType>(
    `${apiInfo.api_url}/page_elements_flow/`,
    {
      params: {
        page_id: info.page_id,
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export { apiGetPageElementsFlow as default };
