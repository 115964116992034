import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../modules';

// Atoms
import DefaultLink from '../../Atoms/Link';

// Molecule
import DefaultNavLink from '../../Atoms/NavLink';
import { FOLD, setFoldNav } from '../../../modules/foldNav';

import CDPLogo from '../../../assets/logo/datawise.png';

const foldAnimation = keyframes`
  from {
    /* width: calc(100% - 220px); */
    width: 220px;
  }
  to {
    width: 96px;
  }
`;

const unfoldAnimation = keyframes`
  from {
    width: 96px;
  }
  to {
    width: 220px;
  }
`;

const Component = styled.div`
  position: relative;
  float: left;
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.white};
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 120px;
  padding: 0px 40px;

  img {
    width: 170px;
    /* height: 100%; */
  }

  svg {
    width: 58px;
    height: 40px;
  }
`;

const FoldTest = styled.div`
  width: 80px;
  height: 30px;
  background-color: black;
`;

const MainMenuWrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey300};
    border-radius: 4px;
  }
`;

const SingleMenuButton = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  padding-left: 20px;
  padding-right: 28px;
  border-radius: 20px;
  margin-bottom: 10px;

  a {
    display: flex;
    align-items: center;
    padding-left: 20px;
    /* padding-right: 40px; */
    height: 100%;
    width: 100%;
    svg {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }
  }
`;

interface NavigationProps extends RouteComponentProps {
  lisenceInfo: {
    pv?: number;
    day?: number;
  };
  userName: string;
  isAIVORYAvailable: boolean;
  onSetLogout: () => void;
  onGetCheckSession: () => void;
  onSetFold: () => void;
  setFold: boolean | null;
}

const Navigation = ({
  location,
  lisenceInfo,
  userName,
  isAIVORYAvailable,
  onSetLogout,
  onGetCheckSession,
  onSetFold,
  setFold,
}: NavigationProps) => {
  return (
    <Component>
      <DefaultLink to="/">
        <LogoWrapper>
          <img src={CDPLogo} alt="" />
        </LogoWrapper>
      </DefaultLink>
      <MainMenuWrapper>
        <SingleMenuButton>
          <DefaultNavLink key="/" to="/" title="서비스 이용 분석" />
        </SingleMenuButton>
        <SingleMenuButton>
          <DefaultNavLink
            key="/serviceAnalytics/environmentAnalysis"
            to="/serviceAnalytics/environmentAnalysis"
            title="사용 환경 분석"
          />
        </SingleMenuButton>
        <SingleMenuButton>
          <DefaultNavLink
            key="/pageAnalytics/uxAnalysisList"
            to="/pageAnalytics/uxAnalysisList"
            title="페이지 UX 분석"
          />
        </SingleMenuButton>
        <SingleMenuButton>
          <DefaultNavLink
            key="/userData"
            to="/userData"
            title="사용자 데이터"
          />
        </SingleMenuButton>
      </MainMenuWrapper>
    </Component>
  );
};

export default withRouter(Navigation);
