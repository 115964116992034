import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import { RootState } from '../../../modules';
import {
  getPageMouseStaticsAsync,
  getPageElementDataAsync,
  setTargetElementId,
  GET_EFFECTIVE_CLICK_RATE,
  GET_PAGE_ATTENTION_SCREENS_COUNT,
  GET_PAGE_DURATION,
  getEffectiveClickRateAsync,
  getPageAttentionScreensCountAsync,
  getPageDurationAsync,
} from '../../../modules/PageAnalytics/UXAnalytics';

import {
  GetPageMouseStaticsParamType,
  GetPageElementDataParamType,
  GetEffectiveClickRateParamType,
  GetPageAttentionScreensCountParamType,
  GetPageDurationParamType,
} from '../../../api/pageAnalytics/uxAnalytics/type';

import { GetPageElementsFlowParamType } from '../../../api/pageAnalytics/uxAnalytics/mouseflow/type';
import { getPageElementsFlowAsync } from '../../../modules/PageAnalytics/UXAnalytics/MouseFlow';
import { GetScrollEventsParamType } from '../../../api/pageAnalytics/uxAnalytics/scroll/type';
import { getScrollEventsAsync } from '../../../modules/PageAnalytics/UXAnalytics/Scroll';

import PageUXAnalysisTemplate from '../../../components/Templates/PageDetailAnalysisTemplates/PageUXAnalysisTemplate';
import { MouseHeatmapTooltipType } from '../../../components/Molecule/Tooltip/MouseHeatmapTooltip';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface UXAnalysisProps extends RouteComponentProps {}

const UXAnalysis = ({ location }: UXAnalysisProps) => {
  // redux
  const { scrollEventsData } = useSelector((state: RootState) => state.scroll);
  const { mouseflowData } = useSelector((state: RootState) => state.mouseFlow);
  const {
    mouseStaticsData,
    elementData,
    effectiveClickRate,
    pageAttentionScreensCount,
    pageDuration,
  } = useSelector((state: RootState) => state.pageUXAnalytics);
  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );
  const start = '2023-01-30';
  const end = '2023-02-28';

  const loading = useSelector((state: RootState) => state.loading);
  const dispatch = useDispatch();

  const onGetScrollEvents = (info: GetScrollEventsParamType) => {
    dispatch(getScrollEventsAsync.request(info));
  };

  const onGetEffectiveClickRate = (info: GetEffectiveClickRateParamType) => {
    dispatch(getEffectiveClickRateAsync.request(info));
  };

  const onGetPageAttentionScreensCount = (
    info: GetPageAttentionScreensCountParamType
  ) => {
    dispatch(getPageAttentionScreensCountAsync.request(info));
  };

  const onGetPageDuration = (info: GetPageDurationParamType) => {
    dispatch(getPageDurationAsync.request(info));
  };

  const onGetPageClickStatics = (info: GetPageMouseStaticsParamType) => {
    dispatch(getPageMouseStaticsAsync.request(info));
  };

  const onGetPageElementData = (info: GetPageElementDataParamType) => {
    dispatch(getPageElementDataAsync.request(info));
  };

  const onSetTargetElementId = (targetElementId: string) => {
    dispatch(setTargetElementId(targetElementId));
  };

  const onGetPageElementsFlow = (info: GetPageElementsFlowParamType) => {
    dispatch(getPageElementsFlowAsync.request(info));
  };

  // state
  const [pageId, setPageId] = useState('');
  const [activeDataType, setActiveDataType] = useState(0);

  const [clickedElementPath, setClickedElementPath] = useState('');
  const [mouseoverElementPath, setMouseoverElementPath] = useState('');

  const [clickedPlusElementPath, setClickedPlusElementPath] = useState('');

  const [clickTooltipInfo, setClickTooltipInfo] =
    useState<MouseHeatmapTooltipType | null>(null);
  const [mouseoverTooltipInfo, setMouseoverTooltipInfo] =
    useState<MouseHeatmapTooltipType | null>(null);

  useEffect(() => {
    onSetTargetElementId('');
    const params: { [key: string]: string } = {};
    location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      (str: string, key: string, value: string) => {
        params[key] = value;
        return value;
      }
    );

    setPageId(params.pageid);
  }, []);

  useEffect(() => {
    if (!isDatePickerToggle && pageId !== '') {
      onGetEffectiveClickRate({
        start_date: start,
        end_date: end,
        page_id: pageId,
      });
      onGetPageAttentionScreensCount({
        start_date: start,
        end_date: end,
        page_id: pageId,
      });
      onGetPageDuration({
        start_date: start,
        end_date: end,
        page_id: pageId,
      });
      onSetTargetElementId('');
    }
  }, [isDatePickerToggle, startDate, endDate, pageId]);

  useEffect(() => {
    if (!isDatePickerToggle && pageId !== '') {
      if (activeDataType === 0) {
        onGetScrollEvents({
          start_date: start,
          end_date: end,
          page_id: pageId,
        });
      } else if (activeDataType === 1) {
        onGetPageElementData({
          start_date: start,
          end_date: end,
          page_id: pageId,
          event_status: 'click',
        });
        onGetPageClickStatics({
          start_date: start,
          end_date: end,
          page_id: pageId,
          event_status: 'click',
        });
      }
      onSetTargetElementId('');
    }
  }, [isDatePickerToggle, startDate, endDate, pageId, activeDataType]);

  return (
    <Component>
      <PageUXAnalysisTemplate
        headerTitle="UX 분석"
        pageId={pageId}
        pageEventStaticsData={mouseStaticsData}
        pageElementData={elementData}
        clickedElementPath={clickedElementPath}
        mouseoverElementPath={mouseoverElementPath}
        clickTooltipInfo={clickTooltipInfo}
        mouseoverTooltipInfo={mouseoverTooltipInfo}
        pageUsabilityData={{
          effectiveClickRate,
          pageAttentionScreensCount,
          pageDuration,
        }}
        pageUsabilityIsLoading={
          loading[GET_EFFECTIVE_CLICK_RATE] ||
          loading[GET_PAGE_ATTENTION_SCREENS_COUNT] ||
          loading[GET_PAGE_DURATION] ||
          false
        }
        activeDataType={activeDataType}
        mouseflowData={mouseflowData}
        clickedPlusElementPath={clickedPlusElementPath}
        scrollEventsData={scrollEventsData}
        loading={loading}
        setClickedElementPath={setClickedElementPath}
        setMouseoverElementPath={setMouseoverElementPath}
        setClickTooltipInfo={setClickTooltipInfo}
        setMouseoverTooltipInfo={setMouseoverTooltipInfo}
        setActiveDataType={setActiveDataType}
        setClickedPlusElementPath={setClickedPlusElementPath}
      />
    </Component>
  );
};

export default withRouter(UXAnalysis);
