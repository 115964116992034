import React from 'react';
import styled from 'styled-components';

import { Pagination } from '@material-ui/lab';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const Component = styled(Pagination)`
  .MuiPaginationItem-page.Mui-selected {
    background-color: ${(props) => props.theme.colors.orange1};
  }
  .MuiPaginationItem-page:hover {
    background-color: ${(props) => props.theme.colors.orange1};
  }
  .MuiPaginationItem-page.Mui-selected:hover,
  .MuiPaginationItem-page.Mui-selected.Mui-focusVisible {
    background-color: ${(props) => props.theme.colors.orange1};
  }
`;

interface PaginationCdpProps {
  maxPage: number;
  page?: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

const PaginationCdp = ({ maxPage, page, handleChange }: PaginationCdpProps) => {
  return (
    <Container>
      <Component
        count={maxPage}
        onChange={handleChange}
        page={page}
        showFirstButton
        showLastButton
      />
    </Container>
  );
};

export default PaginationCdp;
