import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../createRequestSaga';
import {
  GetUserInfoParamType,
  GetBasicInfoParamType,
  GetBasicInfoResponseType,
  GetBrowserInfoParamType,
  GetBrowserInfoResponseType,
  GetDeviceInfoParamType,
  GetDeviceInfoResponseType,
  GetOSInfoParamType,
  GetOSInfoResponseType,
  GetUserInfoResponseType,
  GetPurchaseActivityParamType,
  GetPurchaseActivityResponseType,
  GetUserListResponseType,
  GetUserListParamType,
  GetPageActivityParamType,
  GetPageActivityResponseType,
  GetSessionInfoParamType,
  GetSessionInfoResponseType,
  GetFutureDemandParamType,
  GetFutureDemandResponseType,
  GetTimelineParamType,
  GetTimelineResponseType,
  GetUserPreferenceParamType,
  GetUserPreferenceResponseType,
} from '../../api/userData/type';

// user list paging
export const CHANGE_PAGE = 'userData/CHANGE_PAGE' as const;
export const onChangePage = createAction(CHANGE_PAGE)<number>();

export const GET_USER_LIST = 'userData/GET_USER_LIST' as const;
export const GET_USER_LIST_SUCCESS = 'userData/GET_USER_LIST_SUCCESS' as const;
export const GET_USER_LIST_ERROR = 'userData/GET_USER_LIST_ERROR' as const;

export const GET_BASIC_INFO = 'userData/GET_BASIC_INFO' as const;
export const GET_BASIC_INFO_SUCCESS =
  'userData/GET_BASIC_INFO_SUCCESS' as const;
export const GET_BASIC_INFO_ERROR = 'userData/GET_BASIC_INFO_ERROR' as const;

export const GET_USER_INFO = 'userData/GET_USER_INFO' as const;
export const GET_USER_INFO_SUCCESS = 'userData/GET_USER_INFO_SUCCESS' as const;
export const GET_USER_INFO_ERROR = 'userData/GET_USER_INFO_ERROR' as const;

export const GET_OS_INFO = 'userData/GET_OS_INFO' as const;
export const GET_OS_INFO_SUCCESS = 'userData/GET_OS_INFO_SUCCESS' as const;
export const GET_OS_INFO_ERROR = 'userData/GET_OS_INFO_ERROR' as const;

export const GET_BROWSER_INFO = 'userData/GET_BROWSER_INFO' as const;
export const GET_BROWSER_INFO_SUCCESS =
  'userData/GET_BROWSER_INFO_SUCCESS' as const;
export const GET_BROWSER_INFO_ERROR =
  'userData/GET_BROWSER_INFO_ERROR' as const;

export const GET_DEVICE_INFO = 'userData/GET_DEVICE_INFO' as const;
export const GET_DEVICE_INFO_SUCCESS =
  'userData/GET_DEVICE_INFO_SUCCESS' as const;
export const GET_DEVICE_INFO_ERROR = 'userData/GET_DEVICE_INFO_ERROR' as const;

export const GET_PURCHASE_ACTIVITY = 'userData/GET_PURCHASE_ACTIVITY' as const;
export const GET_PURCHASE_ACTIVITY_SUCCESS =
  'userData/GET_PURCHASE_ACTIVITY_SUCCESS' as const;
export const GET_PURCHASE_ACTIVITY_ERROR =
  'userData/GET_PURCHASE_ACTIVITY_ERROR' as const;

export const GET_PAGE_ACTIVITY = 'userData/GET_PAGE_ACTIVITY' as const;
export const GET_PAGE_ACTIVITY_SUCCESS =
  'userData/GET_PAGE_ACTIVITY_SUCCESS' as const;
export const GET_PAGE_ACTIVITY_ERROR =
  'userData/GET_PAGE_ACTIVITY_ERROR' as const;

export const GET_SESSION_INFO = 'userData/GET_SESSION_INFO' as const;
export const GET_SESSION_INFO_SUCCESS =
  'userData/GET_SESSION_INFO_SUCCESS' as const;
export const GET_SESSION_INFO_ERROR =
  'userData/GET_SESSION_INFO_ERROR' as const;

export const GET_FUTURE_DEMAND = 'userData/GET_FUTURE_DEMAND' as const;
export const GET_FUTURE_DEMAND_SUCCESS =
  'userData/GET_FUTURE_DEMAND_SUCCESS' as const;
export const GET_FUTURE_DEMAND_ERROR =
  'userData/GET_FUTURE_DEMAND_ERROR' as const;

export const GET_TIMELINE = 'userData/GET_TIMELINE' as const;
export const GET_TIMELINE_SUCCESS = 'userData/GET_TIMELINE_SUCCESS' as const;
export const GET_TIMELINE_ERROR = 'userData/GET_TIMELINE_ERROR' as const;

export const GET_USER_PREFERENCE = 'userData/GET_USER_PREFERENCE' as const;
export const GET_USER_PREFERENCE_SUCCESS =
  'userData/GET_USER_PREFERENCE_SUCCESS' as const;
export const GET_USER_PREFERENCE_ERROR =
  'userData/GET_USER_PREFERENCE_ERROR' as const;

export const SET_ERROR_NULL_USER_DATA = 'userData/SET_ERROR_NULL_USER_DATA';

export const getUserListAsync = createAsyncAction(
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_ERROR
)<GetUserListParamType, GetUserListResponseType, AxiosErrorType>();

export const getBasicInfoAsync = createAsyncAction(
  GET_BASIC_INFO,
  GET_BASIC_INFO_SUCCESS,
  GET_BASIC_INFO_ERROR
)<GetBasicInfoParamType, GetBasicInfoResponseType, AxiosErrorType>();

export const getUserInfoAsync = createAsyncAction(
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_ERROR
)<GetUserInfoParamType, GetUserInfoResponseType, AxiosErrorType>();

export const getOSInfoAsync = createAsyncAction(
  GET_OS_INFO,
  GET_OS_INFO_SUCCESS,
  GET_OS_INFO_ERROR
)<GetOSInfoParamType, GetOSInfoResponseType, AxiosErrorType>();

export const getBrowserInfoAsync = createAsyncAction(
  GET_BROWSER_INFO,
  GET_BROWSER_INFO_SUCCESS,
  GET_BROWSER_INFO_ERROR
)<GetBrowserInfoParamType, GetBrowserInfoResponseType, AxiosErrorType>();

export const getDeviceInfoAsync = createAsyncAction(
  GET_DEVICE_INFO,
  GET_DEVICE_INFO_SUCCESS,
  GET_DEVICE_INFO_ERROR
)<GetDeviceInfoParamType, GetDeviceInfoResponseType, AxiosErrorType>();

export const getPurchaseActivityAsync = createAsyncAction(
  GET_PURCHASE_ACTIVITY,
  GET_PURCHASE_ACTIVITY_SUCCESS,
  GET_PURCHASE_ACTIVITY_ERROR
)<
  GetPurchaseActivityParamType,
  GetPurchaseActivityResponseType,
  AxiosErrorType
>();

export const getPageActivityAsync = createAsyncAction(
  GET_PAGE_ACTIVITY,
  GET_PAGE_ACTIVITY_SUCCESS,
  GET_PAGE_ACTIVITY_ERROR
)<GetPageActivityParamType, GetPageActivityResponseType, AxiosErrorType>();

export const getSessionInfoAsync = createAsyncAction(
  GET_SESSION_INFO,
  GET_SESSION_INFO_SUCCESS,
  GET_SESSION_INFO_ERROR
)<GetSessionInfoParamType, GetSessionInfoResponseType, AxiosErrorType>();

export const getFutureDemandAsync = createAsyncAction(
  GET_FUTURE_DEMAND,
  GET_FUTURE_DEMAND_SUCCESS,
  GET_FUTURE_DEMAND_ERROR
)<GetFutureDemandParamType, GetFutureDemandResponseType, AxiosErrorType>();

export const getTimelineAsync = createAsyncAction(
  GET_TIMELINE,
  GET_TIMELINE_SUCCESS,
  GET_TIMELINE_ERROR
)<GetTimelineParamType, GetTimelineResponseType, AxiosErrorType>();

export const getUserPreferenceAsync = createAsyncAction(
  GET_USER_PREFERENCE,
  GET_USER_PREFERENCE_SUCCESS,
  GET_USER_PREFERENCE_ERROR
)<GetUserPreferenceParamType, GetUserPreferenceResponseType, AxiosErrorType>();

export const setErrorNullUserData = createAction(SET_ERROR_NULL_USER_DATA)();
