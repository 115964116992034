import axios from 'axios';
import config from '../../config-api.json';
import { GetPageListResponseType } from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;
const { tenant } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

// pageList 조회
export async function apiGetPageList() {
  const response = await axios.get<GetPageListResponseType>(
    `${apiInfo.api_url}/page_view/`,
    {
      //   params: {
      //     start_date: info.start_date,
      //     end_date: info.end_date,
      //   },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export { apiGetPageList as default };
