import { createReducer } from 'typesafe-actions';
import { FOLD } from './actions';
import { foldNavAction, foldNavState } from './types';

const initialState: foldNavState = {
  isFold: false,
};

const reducer = createReducer<foldNavState, foldNavAction>(initialState, {
  [FOLD]: (state, action) => ({
    ...state,
    isFold: action.payload,
  }),
});

export default reducer;
