import React, { useEffect, useState } from 'react';

import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import Spinner from '../../../Molecule/Spinner';
import UserDataTable from '../../../Molecule/UserDataTable';
import { GetUserListResponseType } from '../../../../api/userData/type';
import PaginationCdp from '../../../Molecule/Pagination';

interface UserListCardProps {
  isLoading?: boolean;
  userList: GetUserListResponseType['payload'];
  onSetPageNum: (page: number) => void;
  pageNumber: number;
}

const UserListCard = ({
  isLoading,
  userList,
  onSetPageNum,
  pageNumber,
}: UserListCardProps) => {
  const [maxPage, setMaxPage] = useState(1);
  const [rebUserList, setRebUserList] = useState<
    GetUserListResponseType['payload']
  >([]);

  const rebuildData = () => {
    const data_temp: UserListCardProps['userList'] = [];
    const data_cnt = 10;
    for (
      let idx = (pageNumber - 1) * data_cnt;
      idx <= pageNumber * data_cnt - 1;
      // eslint-disable-next-line no-plusplus
      idx++
    ) {
      // idx[0] ~ [9]
      const element = userList[idx];
      if (element) {
        data_temp.push(element);
      }
    }
    setRebUserList(data_temp);
  };

  const onChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onSetPageNum(value);
  };

  useEffect(() => {
    setMaxPage(Math.ceil(userList.length / 10));
  }, [userList]);

  useEffect(() => {
    if (userList.length > 0) {
      rebuildData();
    }
  }, [pageNumber, userList]);

  return (
    <CardLayout>
      {isLoading ? (
        <Spinner />
      ) : (
        <CardBodyLayout>
          <UserDataTable idxNum={(pageNumber - 1) * 10} data={rebUserList} />
          <PaginationCdp
            maxPage={maxPage}
            page={pageNumber}
            handleChange={onChange}
          />
        </CardBodyLayout>
      )}
    </CardLayout>
  );
};

export default UserListCard;
