import React from 'react';
import styled from 'styled-components';

import { P1, P2 } from '../../Atoms/Typo';

const Component = styled.div`
  width: 100%;
`;

const TableHeaderRow = styled.div`
  display: flex;
  height: 54px;
  background-color: ${(props) => props.theme.colors.grey100};
`;

const Head = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey200};
  display: flex;
  align-items: center;
  padding-left: 20px;
  ${P2} {
    font-weight: normal;
    color: ${(props) => props.theme.colors.grey500};
  }
`;

const TableBodyRow = styled.div``;

const Column = styled.div`
  width: 100%;
  height: 51px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey200};
  ${P2} {
    padding-left: 20px;
    font-weight: normal;
    color: ${(props) => props.theme.colors.black};
    width: 25%;
  }
`;

interface UserDataTapleProps {
  data: {
    date: string;
    session_cnt: number;
    usage_time: string;
    page_cnt: number;
  }[];
}

const SessionInfoTable = ({ data }: UserDataTapleProps) => {
  const headerData = ['날짜', '세션 수', '평균 세션 시간', '방문 페이지 수'];

  return (
    <Component className="component">
      <TableHeaderRow>
        {headerData.map((element) => {
          return (
            <Head key={element}>
              <P2>{element}</P2>
            </Head>
          );
        })}
      </TableHeaderRow>
      <TableBodyRow className="bodyrow">
        {data.map((element) => {
          return (
            <Column key={element.date}>
              <P2>{element.date}</P2>
              <P2>{element.session_cnt}</P2>
              <P2>{element.usage_time}</P2>
              <P2>{element.page_cnt}</P2>
            </Column>
          );
        })}
      </TableBodyRow>
    </Component>
  );
};

export default SessionInfoTable;
