import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import BasicInformCard from '../../Organisms/Card/UserData/BasicInformCard';
import UserDataDefaultCard from '../../Organisms/Card/UserData/UserDataDefaultCard';
import UserInformCard from '../../Organisms/Card/UserData/UserInformCard';

import {
  GetBasicInfoResponseType,
  GetBrowserInfoResponseType,
  GetDeviceInfoResponseType,
  GetOSInfoResponseType,
  GetTimelineResponseType,
  GetUserInfoResponseType,
} from '../../../api/userData/type';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface UserDataLeftGridProps {
  basicLoading: boolean;
  userLoading: boolean;
  osLoading: boolean;
  browserLoading?: boolean;
  deviceLoading?: boolean;
  timelineLoading?: boolean;
  basicData: GetBasicInfoResponseType['payload'] | null;
  userInfoData: GetUserInfoResponseType['payload'] | null;
  OSInfoData: GetOSInfoResponseType['payload'] | null;
  browserInfoData: GetBrowserInfoResponseType['payload'] | null;
  deviceInfoData: GetDeviceInfoResponseType['payload'] | null;
  timelineData: GetTimelineResponseType['payload'] | null;
}

const UserDataLeftGrid = ({
  basicLoading,
  userLoading,
  osLoading,
  browserLoading,
  deviceLoading,
  timelineLoading,
  basicData,
  userInfoData,
  OSInfoData,
  browserInfoData,
  deviceInfoData,
  timelineData,
}: UserDataLeftGridProps) => {
  const [id, setId] = useState('');
  const [userKey, setUserKey] = useState(['']);
  const [gender, setGender] = useState('');
  const [region, setRegion] = useState('');
  const [os, setOS] = useState('');
  const [browser, setBrowser] = useState('');
  const [device, setDevice] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (basicData) {
      if (basicData.login_id) {
        setId(basicData.login_id);
      } else setId('-');
      setUserKey(basicData.uk_list);
    }
  }, [basicData]);

  useEffect(() => {
    if (userInfoData) {
      setGender(userInfoData.gender);
      setRegion(userInfoData.region);
    }
  }, [userInfoData]);

  useEffect(() => {
    if (OSInfoData) {
      setOS(OSInfoData.os);
    }
  }, [OSInfoData]);

  useEffect(() => {
    if (browserInfoData) {
      setBrowser(browserInfoData.browser);
    }
  }, [browserInfoData]);

  useEffect(() => {
    if (deviceInfoData) {
      setDevice(deviceInfoData.device);
    }
  }, [deviceInfoData]);

  useEffect(() => {
    if (userLoading || osLoading || browserLoading || deviceLoading) {
      setLoading(true);
    } else setLoading(false);
  }, [userLoading, osLoading, browserLoading, deviceLoading]);

  return (
    <Component>
      <BasicInformCard isLoading={basicLoading} id={id} user_key={userKey} />
      <UserInformCard
        isLoading={loading}
        gender={gender}
        region={region}
        os={os}
        browser={browser}
        device={device}
      />
      <UserDataDefaultCard
        title="주 활동 시간"
        isLoading={timelineLoading}
        data={timelineData}
      />
    </Component>
  );
};

export default UserDataLeftGrid;
