import axios from 'axios';
import config from '../config-api.json';
import {
  GetBasicInfoResponseType,
  GetBasicInfoParamType,
  GetBrowserInfoParamType,
  GetDeviceInfoParamType,
  GetOSInfoParamType,
  GetUserInfoParamType,
  GetBrowserInfoResponseType,
  GetDeviceInfoResponseType,
  GetOSInfoResponseType,
  GetUserInfoResponseType,
  GetPurchaseActivityParamType,
  GetPurchaseActivityResponseType,
  GetUserListResponseType,
  GetPageActivityParamType,
  GetPageActivityResponseType,
  GetSessionInfoParamType,
  GetSessionInfoResponseType,
  GetFutureDemandParamType,
  GetFutureDemandResponseType,
  GetTimelineParamType,
  GetTimelineResponseType,
  GetUserPreferenceParamType,
  GetUserPreferenceResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo_cdp } = config;

if (env === 'development') {
  apiInfo_cdp = config.apiInfo_cdp_dev;
}

// 유저 리스트
export async function apiGetUserListInfo() {
  const response = await axios.get<GetUserListResponseType>(
    `${apiInfo_cdp.api_url}/user_list`,
    {
      withCredentials: true,
    }
  );
  return response.data;
}

// 기본 정보
export async function apiGetBasicInfo(info: GetBasicInfoParamType) {
  const response = await axios.get<GetBasicInfoResponseType>(
    `${apiInfo_cdp.api_url}/basic_info`,
    {
      params: {
        user_index: info.user_index,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 사용자 정보
export async function apiGetUserInfo(info: GetUserInfoParamType) {
  const response = await axios.get<GetUserInfoResponseType>(
    `${apiInfo_cdp.api_url}/user_info`,
    {
      params: {
        user_index: info.user_index,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// OS 정보
export async function apiGetOSInfo(info: GetOSInfoParamType) {
  const response = await axios.get<GetOSInfoResponseType>(
    `${apiInfo_cdp.api_url}/os_info`,
    {
      params: {
        user_index: info.user_index,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 브라우저 정보
export async function apiGetBrowserInfo(info: GetBrowserInfoParamType) {
  const response = await axios.get<GetBrowserInfoResponseType>(
    `${apiInfo_cdp.api_url}/browser_info`,
    {
      params: {
        user_index: info.user_index,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 디바이스 정보
export async function apiGetDeviceInfo(info: GetDeviceInfoParamType) {
  const response = await axios.get<GetDeviceInfoResponseType>(
    `${apiInfo_cdp.api_url}/device_info`,
    {
      params: {
        user_index: info.user_index,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 미래 수요
export async function apiGetFutureDemand(info: GetFutureDemandParamType) {
  const response = await axios.get<GetFutureDemandResponseType>(
    `${apiInfo_cdp.api_url}/future_demand`,
    {
      params: {
        user_index: info.user_index,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 구매 활동
export async function apiGetPurchaseActivity(
  info: GetPurchaseActivityParamType
) {
  const response = await axios.get<GetPurchaseActivityResponseType>(
    `${apiInfo_cdp.api_url}/purchase_act`,
    {
      params: {
        user_index: info.user_index,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 페이지 내 활동
export async function apiGetPageActivity(info: GetPageActivityParamType) {
  const response = await axios.get<GetPageActivityResponseType>(
    `${apiInfo_cdp.api_url}/page_act`,
    {
      params: {
        user_index: info.user_index,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 주 활동 시간
export async function apiGetTimeline(info: GetTimelineParamType) {
  const response = await axios.get<GetTimelineResponseType>(
    `${apiInfo_cdp.api_url}/timeline`,
    {
      params: {
        user_index: info.user_index,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 날짜별 세션 정보
export async function apiGetSessionInfo(info: GetSessionInfoParamType) {
  const response = await axios.get<GetSessionInfoResponseType>(
    `${apiInfo_cdp.api_url}/session_info`,
    {
      params: {
        user_index: info.user_index,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 고객 취향
export async function apiGetUserPreference(info: GetUserPreferenceParamType) {
  const response = await axios.get<GetUserPreferenceResponseType>(
    `${apiInfo_cdp.api_url}/user_preference`,
    {
      params: {
        user_index: info.user_index,
      },
      withCredentials: true,
    }
  );
  return response.data;
}
