import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { P1, P2 } from '../../Atoms/Typo';

const Component = styled.div`
  width: 100%;
`;

const TableHeaderRow = styled.div`
  display: flex;
  height: 50px;
`;

const Head = styled.div`
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey200};
  ${P1} {
    font-weight: bold;
    color: ${(props) => props.theme.colors.black};
  }
  :nth-child(1) {
    width: 5%;
  }
  :nth-child(2) {
    width: 60%;
  }
  :nth-child(3) {
    display: flex;
    justify-content: center;
    width: 10%;
  }
  :nth-child(4) {
    display: flex;
    justify-content: center;
    width: 10%;
  }
  :nth-child(5) {
    display: flex;
    justify-content: center;
    width: 15%;
  }
`;

const TableBodyRow = styled.div``;

const Column = styled(Link)`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${P2} {
    font-weight: normal;
    color: ${(props) => props.theme.colors.black};
    :nth-child(1) {
      width: 5%;
    }
    :nth-child(2) {
      width: 60%;
    }
    :nth-child(3) {
      width: 10%;
      display: flex;
      justify-content: end;
    }
    :nth-child(4) {
      width: 10%;
      display: flex;
      justify-content: end;
    }
    :nth-child(5) {
      width: 15%;
      display: flex;
      justify-content: end;
    }
  }
  :hover {
    background-color: ${(props) => props.theme.colors.orange1};
  }
`;

interface UserDataTapleProps {
  idxNum: number;
  data: {
    name: string;
    uk: number;
    session: number;
    time: string;
  }[];
}

const UserDataTable = ({ idxNum, data }: UserDataTapleProps) => {
  const headerData = ['no.', 'User Index', 'UK수', '세션수', '평균 세션 시간'];
  return (
    <Component className="component">
      <TableHeaderRow>
        {headerData.map((element) => {
          return (
            <Head key={element}>
              <P1>{element}</P1>
            </Head>
          );
        })}
      </TableHeaderRow>
      <TableBodyRow className="bodyrow">
        {data.map((element, idx) => {
          return (
            <Column
              key={element.name}
              to={`/userData/detail?user_index=${element.name}`}
            >
              <P2>{idxNum + idx + 1}.</P2>
              <P2>{element.name}</P2>
              <P2>{element.uk}</P2>
              <P2>{element.session}</P2>
              <P2>{element.time}</P2>
            </Column>
          );
        })}
      </TableBodyRow>
    </Component>
  );
};

export default UserDataTable;
