import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import CardHeader from '../../../Molecule/Heading/Card/CardHeader';
import { P2, P1 } from '../../../Atoms/Typo';
import {
  GetPageActivityPayload,
  GetPageActivityResponseType,
} from '../../../../api/userData/type';
import Spinner from '../../../Molecule/Spinner';
import ProgressBar from '../../../Molecule/ProgressBar';

const Compoent = styled(CardLayout)`
  display: flex;
  flex-direction: row;
  padding: 0px 24px;
  margin: 0px 0px 20px;
`;

const Body = styled.div`
  display: flex;
  width: 100%;
`;

const LeftGrid = styled.div`
  width: 30%;
  padding: 24px 0;
  border-right: 1px solid ${(props) => props.theme.colors.grey200};
`;

const ReportSummaryWrap = styled.div`
  ${P1} {
    font-size: 20px;
    font-weight: normal;
    color: ${(props) => props.theme.colors.black};
    span {
      font-weight: bold;
      color: ${(props) => props.theme.colors.orange5};
    }
  }
  ${P2} {
    margin-top: 10px;
    color: ${(props) => props.theme.colors.black};
    font-weight: normal;
  }
`;

const BarDataGrid = styled.div`
  width: 70%;
  padding: 66px 0 0 15px;
`;

interface PageActivityCardProps {
  isLoading?: boolean;
  data: GetPageActivityResponseType['payload'] | null;
}

const PageActivityCard = ({ isLoading, data }: PageActivityCardProps) => {
  const [barData, setBarData] = useState<GetPageActivityPayload['page_list']>(
    []
  );
  const [summary, setSummary] = useState('');
  const [userUsage, setUserUsage] = useState('');
  const [increase, setIncrease] = useState(true);
  const [avgUsage, setAvgUsage] = useState('');

  const userUsageFn = (val: string) => {
    const splVal = val.split(' ');
    if (splVal.length > 1) {
      if (parseInt(splVal[0], 10) < 0) {
        setIncrease(false);
      } else setIncrease(true);
      const hour = Math.abs(parseInt(splVal[0], 10)) * 24;
      const lastIdx = splVal[2].split(':');
      const hour_data = parseInt(lastIdx[0], 10) + hour;
      lastIdx[0] = hour_data.toString();

      setUserUsage(`${lastIdx[0]}시간 ${lastIdx[1]}분 ${lastIdx[2]}초`);
    } else {
      if (val[0] === '-') {
        setIncrease(false);
      } else setIncrease(true);
      const list = val.split(':');
      setUserUsage(`${list[0]}시간 ${list[1]}분 ${list[2]}초`);
    }
  };

  useEffect(() => {
    if (data) {
      setBarData(data.page_list);
      setSummary(data.page_list[0].name);

      userUsageFn(data.sub_usage);

      const list = data.avg_usage.split(':');
      if (list[0] === '0') {
        setAvgUsage(`${list[1]}분 ${list[2]}초`);
      }
    }
  }, [data]);
  return (
    <Compoent>
      {isLoading ? (
        <Spinner />
      ) : (
        <Body>
          <LeftGrid>
            <CardHeaderLayout>
              <CardHeader title="페이지 내 활동" />
            </CardHeaderLayout>
            <ReportSummaryWrap>
              <P1>다른 사용자보다</P1>
              <P1>
                <span>{summary}</span> 이용시간이
              </P1>
              {increase ? (
                <P1>
                  <span>{userUsage}</span> 높습니다.
                </P1>
              ) : (
                <P1>
                  <span>{userUsage}</span> 낮습니다.
                </P1>
              )}
              <P2>
                다른 사용자의 {summary} 평균 이용 시간 {avgUsage}
              </P2>
            </ReportSummaryWrap>
          </LeftGrid>
          <BarDataGrid>
            <ProgressBar data={barData} />
          </BarDataGrid>
        </Body>
      )}
    </Compoent>
  );
};

export default PageActivityCard;
