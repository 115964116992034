import axios from 'axios';
import config from '../../config-api.json';
import {
  GetDashboardStatisticsParamType,
  GetDashboardStatisticsResponseType,
  GetPVMeanStatisticsParamType,
  GetPVMeanStatisticsResponseType,
  GetUVMeanStatisticsParamType,
  GetUVMeanStatisticsResponseType,
  GetUsageMeanStatisticsParamType,
  GetUsageMeanStatisticsResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;
const { tenant } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetTotalUserStatistics(
  info: GetDashboardStatisticsParamType
) {
  const response = await axios.get<GetDashboardStatisticsResponseType>(
    `${apiInfo.api_url}/total_user_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: tenant.t,
        // Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetTotalNewUserStatistics(
  info: GetDashboardStatisticsParamType
) {
  const response = await axios.get<GetDashboardStatisticsResponseType>(
    `${apiInfo.api_url}/total_new_user_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetTotalPageViewStatistics(
  info: GetDashboardStatisticsParamType
) {
  const response = await axios.get<GetDashboardStatisticsResponseType>(
    `${apiInfo.api_url}/total_page_view_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetTotalSessionStatistics(
  info: GetDashboardStatisticsParamType
) {
  const response = await axios.get<GetDashboardStatisticsResponseType>(
    `${apiInfo.api_url}/total_session_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetPVMeanStatistics(
  info: GetPVMeanStatisticsParamType
) {
  const response = await axios.get<GetPVMeanStatisticsResponseType>(
    `${apiInfo.api_url}/pv_mean_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetUVMeanStatistics(
  info: GetUVMeanStatisticsParamType
) {
  const response = await axios.get<GetUVMeanStatisticsResponseType>(
    `${apiInfo.api_url}/uv_mean_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetUsageMeanStatistics(
  info: GetUsageMeanStatisticsParamType
) {
  const response = await axios.get<GetUsageMeanStatisticsResponseType>(
    `${apiInfo.api_url}/usage_mean_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: tenant.t,
      },
      withCredentials: true,
    }
  );
  return response.data;
}
